import gql from "graphql-tag";

import { COMPANY_ATTRIBUTES, COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES } from "../fragments";

export const UPDATE_COMPANY_ADD_SHOP = gql`
    mutation updateCompanyAndAddShop(
        $company: CompanyInput
        $shop: ShopInput
        $extra: RegistrationExtraDTOInput
        $hardware: RegistrationHardwareDTOInput
        $aggregators: [String]
    ) {
        updateCompanyAndAddShop(
            company: $company
            shop: $shop
            extra: $extra
            hardware: $hardware
            aggregators: $aggregators
        )
    }
`;

export const DELETE_COMPANY = gql`
    mutation deleteCompanyMutation($companyId: String!) {
        deleteCompany(companyId: $companyId)
    }
`;

export const CREATE_REGISTER_LINK = gql`
    mutation createRegisterLink($companyName: String!) {
        createRegisterLink(companyName: $companyName)
    }
`;

export const UPDATE_COMPANY_SUBSCRIPTION_SETTINGS = gql`
    mutation updateSubscriptionSettingsByCompanyIdMutation(
        $companyId: String
        $companySubscriptionSettings: CompanySubscriptionSettingsInput
    ) {
        updateSubscriptionSettingsByCompanyId(
            companyId: $companyId
            companySubscriptionSettings: $companySubscriptionSettings
        ) {
            settings {
                companySubscriptionSettings {
                    ...companySubscriptionSettingsResults
                }
            }
        }
    }
    ${COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES}
`;
export const UPDATE_COMPANY = gql`
    mutation updateCompany($company: CompanyInput!) {
        updateCompany(company: $company) {
            ... on Company {
                ...companyResults
            }
        }
    }
    ${COMPANY_ATTRIBUTES}
`;

export const UPDATE_COMPANY_SERVICE_ACTIVATIONS = gql`
    mutation updateCompanyActivations($companyActivationsDTO: CompanyActivationsDTOInput) {
        updateCompanyActivations(companyActivationsDTO: $companyActivationsDTO)
     }
`;

export const START_XML_EXPORT = gql`
    mutation exportDataByDate($companyId: String, $startDate: String, $endDate: String) {
        exportDataByDate(companyId: $companyId, startDate: $startDate, endDate: $endDate)
    }
`;

export const UPDATE_COMPANY_REAL_TIME_CONNECTION = gql`
    mutation updateCompanyRealTimeDataConnection(
        $companyId: String
        $realTimeDataConnection: RealTimeDataConnectionInput
    ) {
        updateCompanyRealTimeDataConnection(companyId: $companyId, realTimeDataConnection: $realTimeDataConnection)
    }
`;

export const CREATE_SUBSCRIPTION_INVOICE = gql`
    mutation createSubscriptionInvoice($companyId: String) {
        createSubscriptionInvoice(companyId: $companyId)
    }
`;
