import gql from "graphql-tag";

import { REF_BUNDLE_PRODUCT_ATTRIBUTES, REF_PRODUCT_ATTRIBUTES } from "../fragments";

export const UPSERT_REF_PRODUCT_CATEGORY = gql`
    mutation upsertProductCategory($refProductCategory: RefProductCategoryInput!) {
        upsertProductCategory(refProductCategory: $refProductCategory) {
            id
            name
            description
            companyId
            sortOrder
        }
    }
`;

export const COPY_REF_PRODUCT = gql`
    mutation copyRefProduct($refProductId: String) {
        copyRefProduct(refProductId: $refProductId) {
            ... on RefProduct {
                ...refProductResults
            }
        }
    }
    ${REF_PRODUCT_ATTRIBUTES}
`;

export const COPY_REF_BUNDLE_PRODUCT = gql`
    mutation copyBundleProduct($refBundleProductId: String) {
        copyBundleProduct(refBundleProductId: $refBundleProductId) {
            ... on RefBundleProduct {
                ...refBundleProductResults
            }
        }
    }
    ${REF_BUNDLE_PRODUCT_ATTRIBUTES}
`;

export const UPDATE_REF_CATEGORIES_SORT_ORDER = gql`
    mutation saveCategorySortOrder($refProductCategoryIds: [String], $companyId: String) {
        saveCategorySortOrder(refProductCategoryIds: $refProductCategoryIds, companyId: $companyId)
    }
`;

export const SAVE_PRODUCTS = gql`
    mutation saveProducts($refProducts: [RefProductInput]) {
        saveProducts(refProducts: $refProducts) {
            ... on RefProduct {
                ...refProductResults
            }
        }
    }
    ${REF_PRODUCT_ATTRIBUTES}
`;

export const SAVE_BUNDLE_PRODUCT = gql`
    mutation saveBundleProduct($refBundleProduct: RefBundleProductInput) {
        saveBundleProduct(refBundleProduct: $refBundleProduct) {
            ... on RefBundleProduct {
                ...refBundleProductResults
            }
        }
    }
    ${REF_BUNDLE_PRODUCT_ATTRIBUTES}
`;

export const SAVE_BUNDLE_PRODUCTS = gql`
    mutation saveBundleProducts($refBundleProducts: [RefBundleProductInput]) {
        saveBundleProducts(refBundleProducts: $refBundleProducts) {
            ... on RefBundleProduct {
                ...refBundleProductResults
            }
        }
    }
    ${REF_BUNDLE_PRODUCT_ATTRIBUTES}
`;

export const DELETE_PRODUCT_CATEGORY = gql`
    mutation deleteProductCategory($companyId: String, $refProductCategoryId: String) {
        deleteProductCategory(companyId: $companyId, refProductCategoryId: $refProductCategoryId)
    }
`;

export const COPY_ITEMS_FROM_COMPANY = gql`
    mutation copyItemsFromCompany(
        $fromCompanyId: String
        $toCompanyId: String
        $menuIds: [String]
        $shouldCopyAddons: Boolean
    ) {
        copyItemsFromCompany(
            fromCompanyId: $fromCompanyId
            toCompanyId: $toCompanyId
            menuIds: $menuIds
            shouldCopyAddons: $shouldCopyAddons
        )
    }
`;
