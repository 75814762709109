import gql from "graphql-tag";

export const ACCOUNT_AUTHENTICATION_ATTRIBUTES = gql`
    fragment accountAuthenticationSettingsResults on AccountAuthenticationSettings {
        id
        thirdPartyAccountingType
        accessToken
        refreshToken
        companyIds
        shopIds
        __typename
    }
`;
