import gql from "graphql-tag";

export const CREATE_PRODUCT_SALES_REPORT = gql`
    query salesReports($shopId: String, $startDate: String, $endDate: String) {
        salesReports(shopId: $shopId, startDate: $startDate, endDate: $endDate) {
            orderType
            sortedByParentNameCountReport {
                domainName
                contentCountAndAmount {
                    contentName
                    contentId
                    extraDataField1
                    children {
                        contentName
                        contentId
                        count
                    }
                }
            }
            sortedByPriceCountAmountReport {
                domainName
                contentCountAndAmount {
                    contentId
                    contentName
                    contentTypeName
                    count
                    amount
                    children {
                        contentName
                        contentId
                        count
                        amount
                    }
                }
            }
            sortedByCountWasteReport {
                domainName
                contentCountAndAmount {
                    contentId
                    contentName
                    contentTypeName
                    count
                    amount
                    extraDataField1
                }
            }
            shopHourlySales {
                dateTime
                amount
            }
            shopCategoryCountAmountReport {
                domainId
                domainName
                domainType
                contentCountAndAmount {
                    contentId
                    contentName
                    contentTypeName
                    count
                    amount
                }
                children {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                }
            }
        }
    }
`;

export const CREATE_X_DAYS_BACK_SHOP_REPORT = gql`
    query createXDaysBackReportShop($reportDate: String, $shopId: String, $xDaysBack: Int) {
        createXDaysBackReportShop(reportDate: $reportDate, shopId: $shopId, xDaysBack: $xDaysBack) {
            orderType
            amountOfOrdersAndTotalSalesSortedBySalesReportForXDaysBack {
                date
                contentCountAmountReports {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                    children {
                        domainId
                        domainName
                        domainType
                        contentCountAndAmount {
                            contentId
                            contentName
                            contentTypeName
                            count
                            amount
                        }
                    }
                }
            }
            popularArticlesReportSortedByQuantityXDaysBack {
                date
                contentCountAmountReports {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                    children {
                        domainId
                        domainName
                        domainType
                        contentCountAndAmount {
                            contentId
                            contentName
                            contentTypeName
                            count
                            amount
                        }
                    }
                }
            }
            popularCategoryReportSortedByQuantityXDaysBack {
                date
                contentCountAmountReports {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                    children {
                        domainId
                        domainName
                        domainType
                        contentCountAndAmount {
                            contentId
                            contentName
                            contentTypeName
                            count
                            amount
                        }
                    }
                }
            }
            hourlyAmountOfOrdersAndTotalSalesSortedBySalesReportForXDaysBack {
                date
                contentCountAmountReports {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                    children {
                        domainId
                        domainName
                        domainType
                        contentCountAndAmount {
                            contentId
                            contentName
                            contentTypeName
                            count
                            amount
                        }
                    }
                }
            }
        }
    }
`;

export const AVERAGE_COOKING_TIME_QUERY = gql`
    query averageCookingTimeShop($reportDate: String, $shopId: String) {
        averageCookingTimeShop(reportDate: $reportDate, shopId: $shopId) {
            date
            compareContentCountAmountReport {
                current {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                }
                last {
                    domainId
                    domainName
                    domainType
                    contentCountAndAmount {
                        contentId
                        contentName
                        contentTypeName
                        count
                        amount
                    }
                }
            }
        }
    }
`;
