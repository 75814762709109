import gql from "graphql-tag";

export namespace AIContentAssistantMutations {
    export type GenerateAIContent = {
        // This should probably be updated to use the OpenAIResponse type
        generateAIContent: string;
    };
}
export const GENERATE_AI_CONTENT = gql`
    mutation generateAIContent(
        $model: String
        $systemPrompt: String
        $userPrompt: String
        $promptModifications: [KeyValueInput]
    ) {
        generateAIContent(
            model: $model
            systemPrompt: $systemPrompt
            userPrompt: $userPrompt
            promptModifications: $promptModifications
        )
    }
`;
