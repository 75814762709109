import gql from "graphql-tag";

export const GET_CUSTOMER_OPT_OUT_TYPE = gql`
    query getCustomerOptOutType($id: String!, $publicShopId: String) {
        getCustomerOptOutType(id: $id, publicShopId: $publicShopId) {
            whichOptOut
            hasOptedOut
        }
    }
`;

export const GET_CUSTOMER_OPT_OUT_BY_EMAIL = gql`
    query getCustomerOptOutByEmail($email: String!) {
        getCustomerOptOutByEmail(email: $email) {
            id
            createdAt
            updatedAt
            deleted
            email
            optOutFrom
            optOutTypePerShops {
                optOutFrom
                publicShopIds
                allShops
            }
        }
    }
`;

export const GET_SHOP_NAME_BY_PUBLIC_ID = gql`
    query getShopByPublicId($publicId: String!) {
        getShopByPublicId(publicId: $publicId) {
            name
        }
    }
`;