import gql from "graphql-tag";
import { ORDER_WINDOW_ATTRIBUTES } from "../fragments";

export const UPDATE_SHOP_TABLES = gql`
    mutation updateShopTables($shopId: String!, $shopTables: ShopTables!) {
        updateShopTables(shopId: $shopId, shopTables: $shopTables)
    }
`;

export const ADD_ORDER_TO_TABLE = gql`
    mutation addOrderToTable($posOrderInput: PosOrderDTOInput!, $shopTableId: String!) {
        addOrderToTable(posOrderInput: $posOrderInput, shopTableId: $shopTableId)
    }
`;

export const UPSERT_ORDER_WINDOW = gql`
    mutation upsertOrderWindow($orderWindow: OrderWindowInput!) {
        upsertOrderWindow(orderWindow: $orderWindow) {
            ... on OrderWindow {
                ...orderWindowResults
            }
        }
    }
    ${ORDER_WINDOW_ATTRIBUTES}
`;

export const UPSERT_ORDER_WINDOW_AND_ORDERS = gql`
    mutation upsertOrderWindowAndOrders($orderWindow: OrderWindowInput!) {
        upsertOrderWindowAndOrders(orderWindow: $orderWindow) {
            ... on OrderWindow {
                ...orderWindowResults
            }
        }
    }
    ${ORDER_WINDOW_ATTRIBUTES}
`;
