import gql from "graphql-tag";
import { CONTACT_INFORMATION_ATTRIBUTES } from "./sharedFragments";
import { SHOP_ATTRIBUTES } from "./shopFragments";

export const COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES = gql`
    fragment companySubscriptionSettingsResults on CompanySubscriptionSettings {
        description
        friendlySubdomainName
        imageUrl
        logo
        minimumPurchaseInterval
        title
        vatRate
        billingInfo {
            billingEmail
            paymentAccount {
                paymentAccountType
                accountNumber
            }
        }
        __typename
    }
`;

/**
 * This is the full set of company stuff, only suitable for admin
 */
export const COMPANY_ATTRIBUTES = gql`
    fragment companyResults on Company {
        publicId
        id
        name
        organisationNumber
        timezone
        email
        standardProductVatRate
        vatRates
        productCategoryIds
        updateChangeNumber
        imageUrl
        registeredBy
        umbrellaCompany
        contactInformation {
            name
            addressLine
            postCode
            city
            country
            phoneNumber
        }
        countryLocale {
            localeCode
            currencyCode
        }
        settings {
            posEnabled
            onlineEnabled
            stockTrackingEnabled
            sellingAlcohol
            thirdPartyAccountingAccessType
            realTimeDataConnection {
                enableRealTimeDataConnection
                endpointUrl
                apiKey
            }
            companyOnlineLandingPage {
                subdomainName
                landingPageEnabled
                backgroundImageUrl
                headerText
                headerTextColor
                breadText
                breadTextColor
                groupLandingPageCardName
                groupLandingPageCardDescription
            }
            companyOnlineSettings {
                facebook
                instagram
            }
            companySubscriptionSettings {
                ... on CompanySubscriptionSettings {
                    ...companySubscriptionSettingsResults
                }
            }
        }
        __typename
    }
    ${COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES}
`;

export const PUBLIC_COMPANY_ATTRIBUTES = gql`
    fragment publicCompanyDTOResults on PublicCompanyDTO {
        id
        imageUrl
        name
        email
        contactInformation {
            ... on ContactInformation {
                ...contactInformationResults
            }
        }
        countryLocale {
            localeCode
            currencyCode
        }
        shops {
            publicId
            name
            desc
            companyId
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
                email
            }
            imageUrl
            bannerUrl
            activeHours {
                dayOfWeek
                startingHour
                stoppingHour
            }
            settings {
                onlineSettings {
                    closeOnlineOrders
                    customClosedMessage
                }
            }
        }
        companyOnlineLandingPage {
            landingPageEnabled
            backgroundImageUrl
            headerText
            headerTextColor
            breadText
            breadTextColor
        }
        companyOnlineSettings {
            facebook
            instagram
        }
        settings {
            companySubscriptionSettings {
                ...companySubscriptionSettingsResults
            }
        }
        __typename
    }
    ${CONTACT_INFORMATION_ATTRIBUTES}
    ${COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES}
`;

export const COMPANY_AND_SHOPS_ATTRIBUTES = gql`
    fragment companyAndShopsResults on CompanyAndShops {
        company {
            ... on Company {
                ...companyResults
            }
        }
        shops {
            ... on Shop {
                ...shopResults
            }
        }
        __typename
    }
    ${COMPANY_ATTRIBUTES}
    ${SHOP_ATTRIBUTES}
`;
