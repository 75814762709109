import gql from "graphql-tag";

export const OPEN_AI_LOG_ATTRIBUTES = gql`
    fragment OpenAILogResults on OpenAILog {
        id
        request {
            model
            messages {
                role
                content
            }
            n
            temperature
        }
        response {
            id
            object
            created
            model
            choices {
                index
                message {
                    role
                    content
                    created
                }
                finish_reason
            }
            usage {
                prompt_tokens
                completion_tokens
                total_tokens
            }
        }
        extras {
            key
            value
        }
        promptModifications {
            key
            value
        }
        type
        owner
        createdAt
        __typename
    }
`;
