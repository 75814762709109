import gql from "graphql-tag";

import { SHOP_OVERRIDE_ATTRIBUTES, SHOP_TABLE_ATTRIBUTES, SHOP_ATTRIBUTES } from "../fragments";

export const UPDATE_SHOP_IP = gql`
    mutation updateCustomerShopIp($shopId: String, $shopName: String, $frontendVersion: String) {
        updateCustomerShopIp(shopId: $shopId, shopName: $shopName, frontendVersion: $frontendVersion)
    }
`;

export const UPDATE_SHOP_BILLING_INFO = gql`
    mutation updateShopBillingInfo($shopId: String, $billingInfo: BillingInfoInput) {
        updateShopBillingInfo(shopId: $shopId, billingInfo: $billingInfo)
    }
`;

export const UPDATE_SHOP_ONLINE_CUSTOM_FEE = gql`
    mutation updateShopOnlineCustomFee($shopId: String, $customVariableRates: CustomVariableRatesInput) {
        updateShopOnlineCustomFee(shopId: $shopId, customVariableRates: $customVariableRates)
    }
`;

export const UPDATE_SHOP_PREDEFINED_COMMENTS = gql`
    mutation updateShopComments($shopId: String, $comments: [String]) {
        updateShopComments(shopId: $shopId, comments: $comments)
    }
`;

export const UPDATE_SINGLE_SHOP_TABLE = gql`
    mutation upsertShopTable($shopTable: ShopTableInput) {
        upsertShopTable(table: $shopTable) {
            ... on ShopTable {
                ...shopTableResults
            }
        }
    }
    ${SHOP_TABLE_ATTRIBUTES}
`;

export const DELETE_SHOP_TABLES = gql`
    mutation deleteShopTable($tableId: String) {
        deleteShopTable(tableId: $tableId)
    }
`;

export const UPSERT_INVOICE_CORRECTION = gql`
    mutation upsertInvoiceCorrection($invoiceCorrection: InvoiceCorrectionInput) {
        upsertInvoiceCorrection(invoiceCorrection: $invoiceCorrection) {
            id
            name
            amount
            incomingVat {
                vatRate
                amount
            }
            account
            vatAccount
            invoiceReferenceId
        }
    }
`;

export const DELETE_INVOICE_CORRECTION = gql`
    mutation deleteInvoiceCorrection($id: String!) {
        deleteInvoiceCorrection(id: $id)
    }
`;

export const UPDATE_SHOPS_MENU_IDS = gql`
    mutation updateShopsMenuIds($shops: [ShopInput]) {
        updateShopsMenuIds(shops: $shops)
    }
`;

export const UPDATE_SERVICE_FEE_FOR_SHOPS = gql`
    mutation updateServiceFeeForShops($shopIds: [String], $serviceFee: ServiceFeeInput) {
        updateServiceFeeForShops(shopIds: $shopIds, serviceFee: $serviceFee)
    }
`;

export const UPSERT_SHOP = gql`
    mutation upsertShop($shop: ShopInput!) {
        upsertShop(shop: $shop) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const DELETE_SHOP = gql`
    mutation deleteShop($shopId: String!) {
        deleteShop(shopId: $shopId)
    }
`;

export const UPSERT_SHOP_OVERRIDE = gql`
    mutation upsertShopOverride($shopOverride: ShopOverrideInput!) {
        upsertShopOverride(shopOverride: $shopOverride) {
            ... on ShopOverride {
                ...shopOverrideResults
            }
        }
    }
    ${SHOP_OVERRIDE_ATTRIBUTES}
`;

export const INCREMENT_ALL_SHOP_UPDATE = gql`
    mutation incrementAllShopUpdate {
        incrementAllShopUpdate
    }
`;

export const UPDATE_PAUSE_ONLINE = gql`
    mutation updatePauseOnline($shopId: String!, $pause: Boolean!) {
        updatePauseOnline(shopId: $shopId, pause: $pause)
    }
`;
export const UPDATE_RUSH_HOUR = gql`
    mutation updaterushHour($shopId: String!, $pause: Boolean!, $time: Int) {
        updateRushHour(shopId: $shopId, pause: $pause, time: $time)
    }
`;

export const UPDATE_PAUSE_HOME_DELIVERY = gql`
    mutation updatePauseHomeDelivery($shopId: String!, $pause: Boolean!) {
        updatePauseHomeDelivery(shopId: $shopId, pause: $pause)
    }
`;

export const UPDATE_ENABLE_EAT_HERE = gql`
    mutation updateEnableEatHere($shopId: String!, $enable: Boolean!) {
        updateEnableEatHere(shopId: $shopId, enable: $enable)
    }
`;

export const UPDATE_CLOSE_ONLINE_ORDERS = gql`
    mutation updateCloseOnlineOrders($shopId: String!, $enable: Boolean!) {
        updateCloseOnlineOrders(shopId: $shopId, enable: $enable)
    }
`;

export const UPDATE_CATERING_SETTINGS = gql`
    mutation updateCateringSettingsMutation($shopId: String!, $cateringSettings: ShopCateringSettingsInput!) {
        updateCateringSettings(shopId: $shopId, cateringSettings: $cateringSettings)
    }
`;

export const SAVE_SHOP_ACCOUNTING_DETAILS = gql`
    mutation saveShopAccountingDetails($shopId: String!, $accountingDetails: AccountingDTOInput!) {
        saveShopAccountingDetails(shopId: $shopId, accountingDetails: $accountingDetails)
    }
`;
export const SAVE_SHOP_INVENTORY = gql`
    mutation upsertShopInventoryStock($shopId: String!, $shopInventoryStocks: [ShopInventoryStockInput]!) {
        upsertShopInventoryStock(shopId: $shopId, shopInventoryStocks: $shopInventoryStocks)
    }
`;

export const ADD_QOPLA_COMMMENT_TO_SHOP = gql`
    mutation addQoplaCommentToShop($shopId: String!, $qoplaComment: String!) {
        addQoplaCommentToShop(shopId: $shopId, qoplaComment: $qoplaComment) {
            username
            addedAt
            qoplaComment
        }
    }
`;

export const DELETE_QOPLA_COMMMENT_TO_SHOP = gql`
    mutation deleteQoplaCommentToShop($shopId: String!, $addedAt: String!, $qoplaComment: String!, $userName: String!) {
        deleteQoplaCommentToShop(shopId: $shopId, addedAt: $addedAt, qoplaComment: $qoplaComment, userName: $userName) {
            username
            addedAt
            qoplaComment
        }
    }
`;

export const UPSERT_EXPRESS_ONLINE_SHOP_SETTINGS = gql`
    mutation upsertExpressOnlineShopSettings(
        $shopId: String!
        $expressOnlineShopSettings: ExpressOnlineShopSettingsDTOInput!
    ) {
        upsertExpressOnlineShopSettings(shopId: $shopId, expressOnlineShopSettings: $expressOnlineShopSettings)
    }
`;

export const UPSERT_EXPRESS_ONLINE_TEXT_COLOR = gql`
    mutation upsertExpressOnlineTextColor($shopId: String!, $expressOnlineTextColor: String!) {
        upsertExpressOnlineTextColor(shopId: $shopId, expressOnlineTextColor: $expressOnlineTextColor)
    }
`;

export const UPSERT_QR_TABLE_SHOP_SETTINGS = gql`
    mutation upsertQrTablesShopSettings($shopId: String!, $qrTablesShopSettings: QRTablesShopSettingsDTOInput!) {
        upsertQrTablesShopSettings(shopId: $shopId, qrTablesShopSettings: $qrTablesShopSettings)
    }
`;

export const UPSERT_AUTO_ACCEPT_SHOP_SETTINGS = gql`
    mutation upsertAutoAcceptShopSettings(
        $shopId: String!
        $enableAutoAcceptThirdPartyOrders: Boolean!
        $predefinedThirdPartyCompletionTime: Int!
    ) {
        upsertAutoAcceptShopSettings(
            shopId: $shopId
            enableAutoAcceptThirdPartyOrders: $enableAutoAcceptThirdPartyOrders
            predefinedThirdPartyCompletionTime: $predefinedThirdPartyCompletionTime
        )
    }
`;

export const UPSERT_SHOP_QR_TABLES = gql`
    mutation upsertShopQrTables($shopId: String!, $shopTables: [ShopTableInput]) {
        upsertShopQrTables(shopId: $shopId, shopTables: $shopTables) {
            id
            name
            tableId
            posId
        }
    }
`;

export const UPSERT_EXPRESS_POS_SHOP_SETTINGS = gql`
    mutation upsertExpressPOSShopSettings($shopId: String!, $expressPOSSettings: ExpressPOSSettingsInput!) {
        upsertExpressPOSShopSettings(shopId: $shopId, expressPOSSettings: $expressPOSSettings)
    }
`;
