import gql from "graphql-tag";

import { ACCOUNT_AUTHENTICATION_ATTRIBUTES } from "../fragments";


export const GET_ACCOUNT_AUTHENTICATION_BY_ID = gql`
    query getAccountAuthenticationById($accountAuthenticationId: String!) {
        getAccountAuthenticationById(accountAuthenticationId: $accountAuthenticationId) {
            ... on AccountAuthenticationSettings {
                ...accountAuthenticationSettingsResults
            }
        }
    }
    ${ACCOUNT_AUTHENTICATION_ATTRIBUTES}
`;

export const GET_ACCCOUNT_AUTHENTICATION_BY_TYPE_AND_COMPANY_ID = gql`
    query getAccountAuthenticationByTypeAndCompany($companyId: String!, $type: ThirdPartyAccountingType!) {
        getAccountAuthenticationByTypeAndCompany(companyId: $companyId, type: $type) {
            ... on AccountAuthenticationSettings {
                ...accountAuthenticationSettingsResults
            }
        }
    }
    ${ACCOUNT_AUTHENTICATION_ATTRIBUTES}
`;

export const GET_ALL_AUTHENTICATION_BY_TYPE_AND_COMPANY_ID = gql`
    query getAllAccountAuthenticationsByTypeAndCompany($companyId: String!, $type: ThirdPartyAccountingType!) {
        getAllAccountAuthenticationsByTypeAndCompany(companyId: $companyId, type: $type) {
            ... on AccountAuthenticationSettings {
                ...accountAuthenticationSettingsResults
            }
        }
    }
    ${ACCOUNT_AUTHENTICATION_ATTRIBUTES}
`;

export const GET_ALL_ACCOUNT_AUTHENTICATION_BY_TYPE_AND_SHOP_ID = gql`
    query getAllAccountAuthenticationsByShopId($shopId: String!, $type: ThirdPartyAccountingType!) {
        getAllAccountAuthenticationsByShopId(shopId: $shopId, type: $type) {
            id
        }
    }
`;