import gql from "graphql-tag";

export const SET_PAYMENT_POSTPONED = gql`
    mutation setPaymentPostponed($orderWindowId: Int) {
        setPaymentPostponed(orderWindowId: $orderWindowId) @client
    }
`;

export const ADD_TO_POS_CART = gql`
    mutation addToPosCart(
        $menuProduct: Object
        $orderWindowId: Int
        $newModifications: Object
        $listRowIndex: Int
        $productAmount: Int
        $menuBundleItems: Object
    ) {
        addToPosCart(
            menuProduct: $menuProduct
            orderWindowId: $orderWindowId
            newModifications: $newModifications
            listRowIndex: $listRowIndex
            productAmount: $productAmount
            menuBundleItems: $menuBundleItems
        ) @client
    }
`;

export const REMOVE_FROM_POS_CART = gql`
    mutation removeFromPosCart($menuProduct: Object, $orderWindowId: Int) {
        removeFromPosCart(menuProduct: $menuProduct, orderWindowId: $orderWindowId) @client
    }
`;

export const ADD_POS_CART = gql`
    mutation addPosCart($newOrderWindowID: Int) {
        addPosCart(newOrderWindowID: $newOrderWindowID) @client
    }
`;

export const REMOVE_ORDER_TAB = gql`
    mutation removeOrderTab($orderWindowId: Int) {
        removeOrderTab(orderWindowId: $orderWindowId) @client
    }
`;

export const REMOVE_POS_LIST_ROW = gql`
    mutation removePosListRow($orderWindowId: Int, $menuProduct: Object) {
        removePosListRow(orderWindowId: $orderWindowId, menuProduct: $menuProduct) @client
    }
`;

export const EMPTY_POS_CART = gql`
    mutation emptyPosCart($orderWindowId: Int) {
        emptyPosCart(orderWindowId: $orderWindowId) @client
    }
`;
