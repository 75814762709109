import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import uuidv4 from "uuid/v4";

import { mothershipLink } from "./sharedApolloConfigs";
import { deepOmitTypename } from "Utils";

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = deepOmitTypename(operation.variables);
    }
    return forward(operation).map(data => {
        return data;
    });
});

// "The uuid is used to prevent caching and mismatch in fragment data";
// https://www.apollographql.com/docs/react/v2/caching/cache-configuration/#custom-identifiers
export const newMothershipApolloClient = new ApolloClient({
    link: ApolloLink.from([cleanTypeName, mothershipLink]),
    cache: new InMemoryCache({
        dataIdFromObject: object => {
            switch (object.__typename) {
                case "OrderProduct":
                    return uuidv4();
                case "RefProduct":
                    return uuidv4();
                case "MenuProduct":
                    return uuidv4();
                case "Order":
                    return uuidv4();
                default:
                    return defaultDataIdFromObject(object); // fall back to default handling
            }
        }
    })
});
