import gql from "graphql-tag";

export const GET_DELIVERY_SERVICES = gql`
    query getThirdPartyDeliveryServiceSettings($shopId: String) {
        getThirdPartyDeliveryServiceSettings(shopId: $shopId) {
            thirdPartyDeliveryType
            externalVendorId
            externalChainCode
            externalShopId
            enableService
            orderKey
            basicAuthUsername
            basicAuthPassword
            basicAuth
            secondaryIntegration
        }
    }
`;
