import gql from "graphql-tag";

import { INGREDIENT_GROUP_ATTRIBUTES } from "../fragments";

export const UPSERT_INGREDIENT_GROUP = gql`
    mutation upsertIngredientGroup($ingredientGroup: IngredientGroupInput!) {
        upsertIngredientGroup(ingredientGroup: $ingredientGroup) {
            ... on IngredientGroup {
                ...ingredientGroupResults
            }
        }
    }
    ${INGREDIENT_GROUP_ATTRIBUTES}
`;

export const DELETE_INGREDIENT_GROUP = gql`
    mutation deleteIngredientGroup($companyId: String, $ingredientGroupId: String) {
        deleteIngredientGroup(companyId: $companyId, ingredientGroupId: $ingredientGroupId)
    }
`;

export const UPDATE_INGREDIENT_THIRD_PARTY_SERVICES = gql`
    mutation updateIngredientThirdPartyServices(
        $companyId: String
        $thirdPartyDeliveryServices: [ThirdPartyDeliveryType]
    ) {
        updateIngredientThirdPartyServices(
            companyId: $companyId
            thirdPartyDeliveryServices: $thirdPartyDeliveryServices
        )
    }
`;
