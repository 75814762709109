import gql from "graphql-tag";
export const CREATE_SIE_FILE_BY_DATE = gql`
    mutation createSIEFileByDate($shopId: String, $startDate: String, $endDate: String) {
        createSIEFileByDate(shopId: $shopId, startDate: $startDate, endDate: $endDate) {
            header
            referenceReportId
            verifications {
                date
                name
                sieTransactions {
                    sieAccountNumber
                    amount
                    costCenter
                }
            }
        }
    }
`;

export const SAVE_SIE_ACCOUNT = gql`
    mutation saveSIEAccount($sieAccount: SIEAccountInput) {
        saveSIEAccount(sieAccount: $sieAccount) {
            id
            companyId
            accountNumber
            name
            description
            productIds
            categoryIds
            paymentMethods
            onlineSaleMethods
            vatRates
            accountType
            cardTypes
        }
    }
`;

export const UPDATE_SIE_ACCOUNT = gql`
    mutation updateSIEAccount($sieAccount: SIEAccountInput) {
        updateSIEAccount(sieAccount: $sieAccount) {
            id
            companyId
            accountNumber
            name
            description
            productIds
            categoryIds
            paymentMethods
            onlineSaleMethods
            vatRates
            accountType
            cardTypes
        }
    }
`;

export const DELETE_SIE_ACCOUNT = gql`
    mutation deleteSIEAccount($accountId: String) {
        deleteSIEAccount(accountId: $accountId)
    }
`;
