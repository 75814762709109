import gql from "graphql-tag";

import { ERROR_ATTRIBUTES, USER_ACCOUNT_ATTRIBUTES } from "../../fragments";

export const REGISTER_USER_ACCOUNT_MUTATION = gql`
    mutation registerUserAccountMutation($input: RegisterInput!) {
        registerUserAccount(input: $input) {
            path
            message
        }
    }
`;

export const LOGOUT_USER_ACCOUNT_MUTATION = gql`
    mutation logoutUserAccountMutation {
        logoutUserAccount
    }
`;

export const USER_ACCOUNT_UPDATE_PASSWORD_MUTATION = gql`
    mutation userAccountUpdatePasswordMutation($input: UpdatePasswordInput!) {
        userAccountUpdatePassword(input: $input) {
            path
            message
        }
    }
`;

export const USER_ACCOUNT_FORGOT_PASSWORD_MUTATION = gql`
    mutation userAccountForgotPasswordMutation($email: String!, $userLanguage: UserLanguage!) {
        userAccountForgotPassword(email: $email, userLanguage: $userLanguage) {
            path
            message
        }
    }
`;

export const UPDATE_USER_ACCOUNT_MUTATION = gql`
    mutation updateUserAccountMutation($input: UserAccountInput!) {
        updateUserAccount(input: $input) {
            ... on Error {
                ...errorResults
            }

            ... on UserAccount {
                ...userAccountResults
            }
        }
    }
    ${ERROR_ATTRIBUTES}
    ${USER_ACCOUNT_ATTRIBUTES}
`;

export const RESET_USER_ACCOUNT_PASSWORD_MUTATION = gql`
    mutation resetUserAccountPasswordMutation($input: ResetPasswordInput!, $userLanguage: UserLanguage!) {
        resetUserAccountPassword(input: $input, userLanguage: $userLanguage) {
            path
            message
        }
    }
`;

export const IMPORT_ORDERS_TO_USER_ACCOUNT_MUTATION = gql`
    mutation importOrdersToUserAccount($email: String!) {
        importOrdersToUserAccount(email: $email)
    }
`;

export const ADD_SHOP_TO_FAVORITES_MUTATION = gql`
    mutation addShopToFavorites($favoriteShopIds: [String!]!) {
        addShopToFavorites(favoriteShopIds: $favoriteShopIds)
    }
`;

export const VERIFY_USER_ACCOUNT_MUTATION = gql`
    mutation verifyUserAccountMutation($token: String!, $shouldLogin: Boolean!) {
        verifyUserAccount(token: $token, shouldLogin: $shouldLogin) {
            ... on Error {
                ...errorResults
            }

            ... on UserAccount {
                ...userAccountResults
            }
        }
    }
    ${ERROR_ATTRIBUTES}
    ${USER_ACCOUNT_ATTRIBUTES}
`;

export const DELETE_USER_ACCOUNT_MUTATION = gql`
    mutation deleteUserAccountMutation($userLanguage: UserLanguage!) {
        deleteUserAccount(userLanguage: $userLanguage)
    }
`;
