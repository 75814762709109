import gql from "graphql-tag";

export const GET_POS_CARTS = gql`
    query getPosCart {
        posCart @client {
            orderWindows {
                orderWindowId
                postponedPayment
                cartProducts {
                    id
                    price
                    priceType
                    menuCategoryId
                    modifications {
                        sizes {
                            name
                            price
                            addonPrice
                        }
                        flavours {
                            name
                            price
                            addonPrice
                        }
                    }
                    selectedBundleProductItems {
                        refProductId
                        refProductCategoryId
                        bundleProductCategoryId
                        kdsUnitDisplayName
                        name
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                    refBundleProduct {
                        id
                        name
                        description
                        defaultPrice
                        priceType
                        vatRate
                        companyId
                        refProductCategoryId
                        articleNumber
                        bundleProductCategories {
                            id
                            name
                            limit
                            refProductIdList
                            kdsUnitDisplayName
                        }
                    }
                    refProduct {
                        id
                        name
                        vatRate
                        defaultPrice
                        priceType
                        refProductCategoryId
                        modifications {
                            sizes {
                                name
                                price
                                addonPrice
                            }
                            flavours {
                                name
                                price
                                addonPrice
                            }
                        }
                    }
                }
            }
        }
    }
`;
