import gql from "graphql-tag";
import { FULL_DISCOUNT_ATTRIBUTES } from "../fragments";

export const VALIDATE_ONLINE_DISCOUNT_CODE = gql`
    mutation validateOnlineDiscountCode(
        $shopId: String
        $discountCode: String
        $eatingOption: EatingOption
        $email: String
    ) {
        validateOnlineDiscountCode(
            shopId: $shopId
            discountCode: $discountCode
            eatingOption: $eatingOption
            email: $email
        ) {
            id
            name
            code
            amount
            rate
            startDate
            endDate
            oneEmailLimit
            onlineCustomerMessage
            emailOnlyDiscount
            campaignId
            menuCategoryAndProductIds {
                menuId
                categoryIds
                productIds
            }
        }
    }
`;

export const UPSERT_DISCOUNT = gql`
    mutation upsertDiscount($discount: DiscountInput) {
        upsertDiscount(discount: $discount) {
            ... on Discount {
                ...discountResults
            }
        }
    }
    ${FULL_DISCOUNT_ATTRIBUTES}
`;

export const DELETE_DISCOUNT = gql`
    mutation deleteDiscount($companyId: String!, $discountId: String!) {
        deleteDiscount(companyId: $companyId, discountId: $discountId)
    }
`;
