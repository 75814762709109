import gql from "graphql-tag";

export const ADD_BUNDLE_TO_EXPRESS_CART = gql`
    mutation addBundleToExpressCart(
        $menuBundleProduct: Object
        $menuBundleProductItems: Object
        $bundleProductCategories: Object
    ) {
        addBundleToExpressCart(
            menuBundleProduct: $menuBundleProduct
            menuBundleProductItems: $menuBundleProductItems
            bundleProductCategories: $bundleProductCategories
        ) @client
    }
`;

export const ADD_MENU_PRODUCT_TO_EXPRESS_CART = gql`
    mutation addMenuProductToExpressCart($menuProduct: Object) {
        addMenuProductToExpressCart(menuProduct: $menuProduct) @client
    }
`;

export const PAIR_EXPRESS_POS_WITH_DEVICE = gql`
    mutation pairExpressPosWithDevice($expressPosId: String, $deviceCookieId: String) {
        pairExpressPosWithDevice(expressPosId: $expressPosId, deviceCookieId: $deviceCookieId)
    }
`;

export const INCREMENT_OR_DECREMENT_EXPRESS_CART_PRODUCT = gql`
    mutation incrementOrDecrementExpressCartProduct($orderProduct: Object, $typeOfOperation: String) {
        incrementOrDecrementExpressCartProduct(orderProduct: $orderProduct, typeOfOperation: $typeOfOperation) @client
    }
`;

export const REMOVE_EXPRESS_CART_PRODUCT = gql`
    mutation removeExpressCartProduct($orderProduct: Object) {
        removeExpressCartProduct(orderProduct: $orderProduct) @client
    }
`;

export const EMPTY_EXPRESS_CART = gql`
    mutation emptyExpressCart {
        emptyExpressCart @client
    }
`;

export const UPDATE_EXPRESS_CART_ORDER_PRODUCT_MODIFICATIONS = gql`
    mutation updateExpressCartOrderProductModifications(
        $orderProduct: Object
        $newModifications: Object
        $newAddons: [Object]
    ) {
        updateExpressCartOrderProductModifications(
            orderProduct: $orderProduct
            newModifications: $newModifications
            newAddons: $newAddons
        ) @client
    }
`;

export const UPDATE_EXPRESS_CART_BUNDLE_PRODUCT = gql`
    mutation updateExpressCheckoutBundleProduct(
        $bundleOrderProduct: Object
        $newMenuBundleProductItems: Object
        $bundleProductCategories: Object
    ) {
        updateExpressCheckoutBundleProduct(
            bundleOrderProduct: $bundleOrderProduct
            newMenuBundleProductItems: $newMenuBundleProductItems
            bundleProductCategories: $bundleProductCategories
        ) @client
    }
`;
export const UPDATE_BUNDLE_PRODUCT_MODIFICATION = gql`
    mutation updateBundleProductModification(
        $menuProduct: Object
        $newModifications: Object
        $refProductToUpdate: Object
    ) {
        updateBundleProductModification(
            menuProduct: $menuProduct
            newModifications: $newModifications
            refProductToUpdate: $refProductToUpdate
        ) @client
    }
`;

export const SET_WHERE_TO_EAT = gql`
    mutation setWhereToEat($takeAway: Boolean) {
        setWhereToEat(takeAway: $takeAway) @client
    }
`;

export const UPDATE_PRODUCT_COMMENT = gql`
    mutation updateProductComment($orderProduct: Object, $comment: String) {
        updateProductComment(orderProduct: $orderProduct, comment: $comment) @client
    }
`;

export const SET_TABLE_META = gql`
    mutation setTableMeta($tableName: String) {
        setTableMeta(tableName: $tableName) @client
    }
`;
