import gql from "graphql-tag";

export const GET_JOURNAL_MEMORY = gql`
    query getJournalMessages(
        $parentId: [String]!
        $startDateTime: String
        $endDateTime: String
        $pageNumber: Int
        $pageItems: Int
    ) {
        getJournalMessages(
            parentId: $parentId
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            pageNumber: $pageNumber
            pageItems: $pageItems
        ) {
            createdAt
            parentId
            className
            instanceId
            instanceName
            message
            value
        }
        getJournalMessageCount(
            parentId: $parentId
            startDateTime: $startDateTime
            endDateTime: $endDateTime
        )
    }
`;
