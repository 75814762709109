import gql from "graphql-tag";

export const ADD_USER_GIFT_CARD = gql`
    mutation addGiftCardMutation($giftCardOrderDTO: GiftCardOrderDTOInput) {
        addGiftCard(giftCardOrderDTO: $giftCardOrderDTO) {
            order {
                id
            }
            webPaymentResponse {
                operationSuccess
                qrCode
                token
                transactionId
                redirectUrl
                errorText
            }
        }
    }
`;

export const PROCESS_GIFT_CARD_PAYMENT = gql`
    mutation processGiftCardPayment($userGiftCardId: String) {
        processGiftCardPayment(userGiftCardId: $userGiftCardId) {
            webPaymentResponse {
                operationSuccess
                errorText
            }
            order {
                shopId
                paymentStatus
            }
        }
    }
`;

export const CLAIM_USER_GIFT_CARD = gql`
    mutation claimUserGiftCard($userGiftCardId: String, $userAccountId: String) {
        claimUserGiftCard(userGiftCardId: $userGiftCardId, userAccountId: $userAccountId)
    }
`;
