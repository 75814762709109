import gql from "graphql-tag";

import { CUSTOMER_REGISTRATION_ATTRIBUTES } from "../fragments/customerRegistrationFragments";

export const CREATE_CUSTOMER_REGISTRATION = gql`
    mutation createCustomerRegistration($shopCreation: Boolean) {
        createCustomerRegistration(shopCreation: $shopCreation) {
            ... on CustomerRegistration {
                ...customerRegistrationResults
            }
        }
    }
    ${CUSTOMER_REGISTRATION_ATTRIBUTES}
`;

export const UPDATE_CUSTOMER_REGISTRATION = gql`
    mutation updateCustomerRegistration($customerRegistration: CustomerRegistrationInput) {
        updateCustomerRegistration(customerRegistration: $customerRegistration)
    }
`;

export const SUBMIT_CUSTOMER_REGISTRATION = gql`
    mutation submitCustomerRegistration($customerRegistration: CustomerRegistrationInput) {
        submitCustomerRegistration(customerRegistration: $customerRegistration)
    }
`;

export const DELETE_CUSTOMER_REGISTRATION = gql`
    mutation deleteCustomerRegistration($customerRegistrationId: String) {
        deleteCustomerRegistration(customerRegistrationId: $customerRegistrationId)
    }
`;
