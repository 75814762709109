import gql from "graphql-tag";

export const CLEAR_ALL_KITCHEN_ORDERS_AND_BATCH = gql`
    mutation clearAllKitchenOrdersAndBatches($shopId: String, $isDriveThrough: Boolean) {
        setAllBatchesToDone(shopId: $shopId)
        setAllKitchenOrdersToDelivered(shopId: $shopId, isDriveThrough: $isDriveThrough)
    }
`;

export const START_KITCHEN_BATCH = gql`
    mutation startBatch($batchId: String) {
        startBatch(batchId: $batchId)
    }
`;

export const SET_BATCH_TO_DONE = gql`
    mutation setBatchDone($batchId: String) {
        setBatchDone(batchId: $batchId)
    }
`;

export const TEST_ORDER_PRINTER = gql`
    mutation sendTestPrintOrder($shopId: String) {
        sendTestPrintOrder(shopId: $shopId)
    }
`;

export const TEST_ORDER_WEBSOCKET = gql`
    mutation createTestKitchenOrder($shopId: String) {
        createTestKitchenOrder(shopId: $shopId)
    }
`;

export const EMPTY_PRINTER_QUEUE = gql`
    mutation emptyPrinterQueue($shopId: String) {
        emptyPrinterQueue(shopId: $shopId)
    }
`;

export const REPRINT_KITCHEN_ORDER = gql`
    mutation rePrintKitchenOrder($kitchenOrder: KitchenOrderInput) {
        rePrintKitchenOrder(kitchenOrder: $kitchenOrder)
    }
`;

export const REPRINT_KITCHEN_ORDER_BY_ORDER_ID = gql`
    mutation rePrintKitchenOrderByOrderIdMutation($orderId: String) {
        rePrintKitchenOrderByOrderId(orderId: $orderId)
    }
`;
