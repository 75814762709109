import gql from "graphql-tag";

export const USER_GIFT_CARD_ATTRIBUTES = gql`
    fragment userGiftCardResults on UserGiftCard {
        id
        userAccountId
        status
        giftCardRedemptionOrders
        giftCardPurchaseOrderId
        latestOrderTimestamp
        expirationDate
        deliveryDate
        buyerInformation {
            onlineContactInformation {
                name
                lastName
                email
                phoneNumber
                phoneNumber2
                addressLine
                postCode
                floorNumber
                doorCode
                city
            }
            paymentInformation {
                paymentMethod
                swishPhoneNumber
            }
            deviceInformation {
                isBrowser
            }
        }
        recipientDetails {
            recipientEmail
            recipientName
            recipientMessage
        }
        shopId
        companyId
        initialAmount
        remainingAmount
        createdAt
        updatedAt
        disabled
        deleted
        __typename
    }
`;

export const USER_GIFT_CARD_DTO_ATTRIBUTES = gql`
    fragment userGiftCardDTOResults on UserGiftCardDTO {
        userAccountId
        clientInformation
        contactInformation
        deviceInformation
        userLanguage
        shopId
        qr
        recipientDetails
        amount
        __typename
    }
`;

export const USER_GIFT_CARD_WITH_SETTINGS = gql`
    fragment userGiftCardDTOSettingsResults on UserGiftCardDTO {
        id
        userAccountId
        status
        giftCardRedemptionOrders
        giftCardPurchaseOrderId
        latestOrderTimestamp
        expirationDate
        buyerInformation {
            onlineContactInformation {
                name
                lastName
                email
            }
        }
        recipientDetails {
            recipientEmail
            recipientName
            recipientMessage
        }
        shopId
        companyId
        initialAmount
        remainingAmount
        giftCardSettings {
            id
            imageUrl
            name
            shopIds
            enabledForAllShops
            companyId
        }
        __typename
    }
`;
