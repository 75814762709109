import gql from "graphql-tag";

export const SAVE_DELIVERY_SERVICE_SETTING = gql`
    mutation saveThirdPartyDeliveryServiceSetting(
        $shopId: String
        $thirdPartyDeliveryServices: [ThirdPartyDeliveryServiceInput]
    ) {
        saveThirdPartyDeliveryServiceSetting(shopId: $shopId, thirdPartyDeliveryServices: $thirdPartyDeliveryServices)
    }
`;
