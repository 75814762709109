import gql from "graphql-tag";
/* Graphql mutations: */

export const UPSERT_USER = gql`
    mutation upsertCompanyUser($user: CompanyUserInput!, $removeTopicIdFromShop: [String]!) {
        upsertCompanyUser(user: $user, removeTopicIdFromShop: $removeTopicIdFromShop) {
            createdAt
            id
            email
            firstName
            lastName
            phoneNr
            roles
            shopIds
            passwordIsSet
            umbrellaCompanyIds
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteCompanyUser($companyUserId: String!) {
        deleteCompanyUser(companyUserId: $companyUserId)
    }
`;

export const UPSERT_UMBRELLA_ADMIN_USER = gql`
    mutation upsertUmbrellaAdminUser($companyUserId: String!, $umbrellaCompanyId: String!) {
        upsertUmbrellaAdminUser(companyUserId: $companyUserId, umbrellaCompanyId: $umbrellaCompanyId) {
            createdAt
            id
            email
            firstName
            lastName
            phoneNr
            roles
            shopIds
            passwordIsSet
            umbrellaCompanyIds
        }
    }
`;
