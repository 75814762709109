import gql from "graphql-tag";

export const USER_ACCOUNT_ATTRIBUTES = gql`
    fragment userAccountResults on UserAccount {
        id
        imageUrl
        contactInformation {
            email
            lastName
            name
            phoneNumber
            addressLine
            postCode
            doorCode
            floorNumber
            city
        }
        createdAt
        isGoogleAccount
        acceptsMarketing
        hasMigratedHistoryOrders
    }
`;
