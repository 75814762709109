import gql from "graphql-tag";

export const DELETE_GROUP_LANDING_PAGE = gql`
    mutation deleteLandingPage($id: String) {
        deleteLandingPage(id: $id)
    }
`;

export const UPSERT_GROUP_LANDING_PAGE = gql`
    mutation saveLandingPage($groupLandingPage: GroupLandingPageInput) {
        saveLandingPage(groupLandingPage: $groupLandingPage) {
            id
            name
            logoUrl
            facebook
            instagram
            tiktok
            publicShopIds
            sortedPublicShopIds {
                id
                sortNumber
            }
            sortedCompanyLandingPageIds {
                id
                sortNumber
            }
            landingPage {
                subdomainName
                landingPageEnabled
                backgroundImageUrl
                headerText
                headerTextColor
                breadText
                breadTextColor
                expressOnline
            }
        }
    }
`;
