import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { Observable } from "apollo-client/util/Observable";

import { REFETCH_USER_ACCOUNT_TOKEN_QUERY } from "GraphQLQueries";
import { envConstants } from "Constants";
import introspectionQueryResultData from "../../generated/introspection-result";
import history from "../../history";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const userAccountHttpLink = new HttpLink({
    uri: `${envConstants.USER_ACCOUNT_SERVICE_URL}/graphql`,
    credentials: "include"
});

const cache = new InMemoryCache({
    fragmentMatcher
});

let apolloClient: ApolloClient<object>;

const getNewAccessToken = () => {
    return apolloClient.query({
        query: REFETCH_USER_ACCOUNT_TOKEN_QUERY,
        fetchPolicy: "network-only"
    });
};

apolloClient = new ApolloClient({
    link: ApolloLink.from([
        onError(({ operation, graphQLErrors, forward }) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    switch (err.message) {
                        case "Unauthorized": {
                            if (err.path?.includes("refetchRefetchToken")) {
                                return history.push("/logout-user-account", {
                                    state: { prevPathName: history.location.pathname }
                                });
                            } else {
                                return new Observable(observer => {
                                    getNewAccessToken().then(() => {
                                        const subscriber = {
                                            next: observer.next.bind(observer),
                                            error: observer.error.bind(observer),
                                            complete: observer.complete.bind(observer)
                                        };

                                        forward(operation).subscribe(subscriber);
                                    });
                                });
                            }
                        }

                        default:
                            break;
                    }
                }
            }
        }),
        userAccountHttpLink
    ]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
            errorPolicy: "all"
        },
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all"
        }
    },
    cache
});

export const typedApolloClient = apolloClient;
