import gql from "graphql-tag";

export const SHOP_TABLE_ATTRIBUTES = gql`
    fragment shopTableResults on ShopTable {
        id
        name
        posId
        description
        coordinateX
        coordinateY
        occupied
        width
        height
        shape
        objectType
        createdAt
        orderId
        shopId
        tableId
        status
        updatedAt
    }
`;
