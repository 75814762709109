import gql from "graphql-tag";

export const CREATE_SIE_FILE_BY_REPORT_ID = gql`
    mutation createSIEFileByReportId($companyId: String, $shopId: String, $reportId: String) {
        createSIEFileByReportId(companyId: $companyId, shopId: $shopId, reportId: $reportId) {
            header
            referenceReportId
            failureReason
            verifications {
                date
                name
                sieTransactions {
                    sieAccountNumber
                    amount
                    costCenter
                }
            }
        }
    }
`;

export const FORCE_CREATE_SIE_FILE = gql`
    mutation forceCreateSIEFile($companyId: String, $shopId: String, $reportId: String, $isOnline: Boolean) {
        forceCreateSIEFile(companyId: $companyId, shopId: $shopId, reportId: $reportId, isOnline: $isOnline) {
            header
            referenceReportId
            failureReason
            verifications {
                date
                name
                sieTransactions {
                    sieAccountNumber
                    amount
                    costCenter
                }
            }
        }
    }
`;

export const FORCE_CREATE_ALL_SIE_FILE = gql`
    mutation forceCreateAllSIEFile($companyId: String, $shopId: String, $isOnline: Boolean) {
        forceCreateAllSIEFile(companyId: $companyId, shopId: $shopId, isOnline: $isOnline)
    }
`;
