import gql from "graphql-tag";

import { OPEN_AI_LOG_ATTRIBUTES } from "../fragments";
import { OpenAIRequest, OpenAIResponse, OpenAILog, OpenAILogType } from "Types";

export namespace AIContentAssistantQueries {
    export type GetOpenAILogs = {
        getOpenAILogs: OpenAILog[];
    };
}

export const GET_OPEN_AI_LOGS = gql`
    query getOpenAILogs {
        getOpenAILogs {
            ... on OpenAILog {
                ...OpenAILogResults
            }
        }
    }
    ${OPEN_AI_LOG_ATTRIBUTES}
`;
