import gql from "graphql-tag";

export const GET_CATEGORIES_WITH_PRODUCTS_BY_COMPANY_ID = gql`
    query getCategoriesWithProducts($companyId: String!) {
        getCategoriesWithProducts(companyId: $companyId) {
            id
            name
            description
            companyId
            sortOrder
            refProductList {
                id
                companyId
                name
                defaultPrice
                refProductCategoryId
                vatRate
                deleted
            }
            refBundleProductList {
                id
                companyId
                name
                defaultPrice
                vatRate
                description
                refProductCategoryId
            }
        }
    }
`;
