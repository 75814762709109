import { HttpLink } from "apollo-link-http";
import { onError, ErrorLink } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { RetryLink } from "apollo-link-retry";

import { authorizationBearerToken } from "Utils";
import { envConstants } from "Constants";
import history from "../../history";

const mSkipRetryOnOperations = [
    "updateOnlineOrderStatus",
    "changeKitchenOrderStatus",
    "logout",
    "purchasePosOrder",
    "purchaseCloudCardPosOrder",
    "refundPosOrder",
    "extendPostponedOrder",
    "payPostponedPaymentOrder",
    "createReceiptCopy",
    "pingControlUnit",
    "heartBeat"
];

const httpLink = new HttpLink({
    uri: `${envConstants.BACKEND_URL}/graphql`
});

const requestLink = new ApolloLink((operation, forward) => {
    const token = authorizationBearerToken(operation.operationName);
    operation.setContext({
        headers: {
            authorization: token
        }
    });
    return forward(operation);
});

const retryLink = new RetryLink({
    delay: {
        initial: 200,
        max: 800,
        jitter: false
    },
    attempts: {
        max: 2,
        retryIf: (error, operation) => !mSkipRetryOnOperations.includes(operation.operationName) && !!error
    }
});

const updateAuthTokenLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const context = operation.getContext();
        const {
            response: { headers }
        } = context;

        if (headers) {
            const token = headers.get("x-refresh-token");
            const tokenIssueTimeMs = headers.get("x-refresh-time");
            const tokenIssueTimeSeconds = (tokenIssueTimeMs / 1000).toFixed(0);

            if (token && sessionStorage.getItem("jwtToken") && operation.operationName !== "logout") {
                sessionStorage.setItem("jwtToken", token);
                sessionStorage.setItem("lastContactWithBackend", tokenIssueTimeSeconds);
            }
        }
        return response;
    });
});

const errorHandler: ErrorLink.ErrorHandler = ({ operation, graphQLErrors, networkError }) => {
    if (operation.operationName === "pingBackend") {
        return;
    }
    if (graphQLErrors) {
        if (graphQLErrors[0].message.includes("Access is denied")) {
            if (history && history.location && !history.location.pathname.includes("/admin")) {
                history.push("/notFound");
            }
        }
    }
    if (networkError) {
        if (networkError.message !== "Failed to fetch" && !networkError.message.includes("Unexpected token <")) {
            if (history && history.location) {
                if (history.location.pathname.includes("/admin")) {
                    history.push("/logout", {
                        state: {
                            from: history.location.pathname,
                            redirectReasons: {
                                networkError
                            }
                        }
                    });
                } else {
                    history.push(history.location.pathname);
                }
            }
        }
    }
};

export const mothershipLink = ApolloLink.from([
    retryLink,
    onError(errorHandler),
    requestLink,
    updateAuthTokenLink.concat(httpLink)
]);
