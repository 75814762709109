import gql from "graphql-tag";

import { ACTIVE_HOUR_ATTRIBUTES, CONTACT_INFORMATION_ATTRIBUTES } from "./sharedFragments";
import { COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES } from "./shopFragments";

export const CUSTOMER_REGISTRATION_ATTRIBUTES = gql`
    fragment customerRegistrationResults on CustomerRegistration {
        id
        registeredBy
        status
        billingAddressSameAsCompanyAddress
        payoutEmailSameAsContactEmail

        shopBillingInfo {
            ... on BillingInfo {
                ...shopBillingInfoResults
            }
        }
        shopContactInformation {
            ... on ContactInformation {
                ...contactInformationResults
            }
        }
        shopActiveHours {
            ... on ActiveHour {
                ...activeHourResults
            }
        }
        shopName
        shopOrganisationNumber

        companyName
        companyId
        countryLocale {
            localeCode
            currencyCode
        }
        vatRates
        standardProductVatRate
        companyContactInformation {
            ... on ContactInformation {
                ...contactInformationResults
            }
        }

        cashDrawer
        numSmallPOS
        numMediumPOS
        numLargePOS
        numReceiptPrinters
        numExpressOnlineTable
        numExpressOnlineFloor
        numLegacyExpressTable
        numLegacyExpressFloor
        numOrderDisplayTablets
        numOrderDisplayWithBuiltInComputer
        numPickupDisplays
        otherHardwareNotes
        numBusinessCards
        expressPOS
        numSunmi

        wantsQoplaHomepage
        webpageUrl

        wantsButtonsOnOwnHomepage
        ftpHost
        ftpUsername
        ftpPassword

        needFullMenu
        needPartialMenu
        needNoMenu
        needDeliveryMenu
        otherMenuNotes

        contractSigned
        agreedLastStartDate
        posTypes
        eatingOptions
        messageToQopla
        images {
            fileName
            base64String
        }

        accountingIntegrations
        staffManagementIntegrations
        thirdPartyIntegrations
        sameThirdPartyMenuPrices
        updatedAt
        shopCreation
        __typename
    }
    ${COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES}
    ${CONTACT_INFORMATION_ATTRIBUTES}
    ${ACTIVE_HOUR_ATTRIBUTES}
    ${CONTACT_INFORMATION_ATTRIBUTES}
`;
