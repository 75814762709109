import gql from "graphql-tag";
import { CREDIT_CARD_ATTRIBUTES, ORDER_ATTRIBUTES } from ".";

export const PRICE_PLAN_ATTRIBUTES = gql`
    fragment pricePlanResults on PricePlan {
        amount
        imageUrl
        name
        paymentFrequencyInMonths
        __typename
    }
`;

export const SUBSCRIPTION_ATTRIBUTES = gql`
    fragment subscriptionResults on Subscription {
        id
        name
        description
        imageUrl
        companyId
        shopIds
        pricePlans {
            ... on PricePlan {
                ...pricePlanResults
            }
        }
        subscriptionProducts {
            amountDiscount
            percentageDiscount
            refProductId
        }
        sortOrder
        hidden
        __typename
    }
    ${PRICE_PLAN_ATTRIBUTES}
`;

export const GROUP_SUBSCRIPTION_CONTACT_INFORMATION_ATTRIBUTES = gql`
    fragment groupSubscriptionContactInformationResults on GroupSubscriptionContactInformation {
        addressLine
        city
        companyName
        contactEmail
        contactName
        contactPhoneNumber
        country
        organisationNumber
        postCode
        __typename
    }
`;
export const USER_SUBSCRIPTION_ATTRIBUTES = gql`
    fragment userSubscriptionResults on UserSubscriptionDTO {
        id
        status
        subscriptionRedemptionOrders
        recurringPaymentOrders
        preferredShopId
        subscriptionType
        subscriptionPaymentType
        subscriptionId
        creditCardInfo {
            ... on CreditCardInfo {
                ...creditCardInfoResults
            }
        }
        latestOrderTimestamp
        latestRecurringPaymentTimestamp
        subscriptionCancelReason {
            reason
            detailedReason
            cancelledTimestamp
        }
        currentRetryStatus {
            nextScheduledRetryDate
            failedRetryOrderIds
        }
        chosenPricePlan {
            ... on PricePlan {
                ...pricePlanResults
            }
        }
        subscription {
            ... on Subscription {
                ...subscriptionResults
            }
        }
        createdAt
        __typename
    }
    ${SUBSCRIPTION_ATTRIBUTES}
    ${CREDIT_CARD_ATTRIBUTES}
    ${PRICE_PLAN_ATTRIBUTES}
`;

export const USER_SUBSCRIPTION_ATTRIBUTES_ORDERS_DTO = gql`
    fragment userSubscriptionAndOrderResults on UserSubscriptionAndOrderDTO {
        id
        customerEmail
        customerName
        status
        subscriptionRedemptionOrders
        upgradePaymentOrders
        recurringPaymentOrders
        preferredShopId
        subscriptionType
        subscriptionId
        userAccountId
        createdAt
        deleted
        disabled
        creditCardInfo {
            ... on CreditCardInfo {
                ...creditCardInfoResults
            }
        }
        latestOrderTimestamp
        latestRecurringPaymentTimestamp
        subscriptionCancelReason {
            reason
            detailedReason
            cancelledTimestamp
        }
        currentRetryStatus {
            nextScheduledRetryDate
            failedRetryOrderIds
        }
        chosenPricePlan {
            ... on PricePlan {
                ...pricePlanResults
            }
        }
        subscriptionPayments {
            id
            purchaseDate
            paymentStatus
            totalNetAmount
            totalAmount
            receiptType
            refundStatus
            referenceOrderId
            vatRatesAndAmounts {
                vatRate
            }
            subscriptionMeta {
                name
                subscriptionId
                userSubscriptionId
                vatRate
            }
            userInformation {
                onlineContactInformation {
                    name
                    lastName
                    email
                    phoneNumber
                    phoneNumber2
                    addressLine
                    postCode
                    floorNumber
                    doorCode
                    city
                }
            }
        }
        __typename
    }
    ${CREDIT_CARD_ATTRIBUTES}
    ${PRICE_PLAN_ATTRIBUTES}
`;

export const GROUP_SUBSCRIPTION_ATTRIBUTES = gql`
    fragment groupSubscriptionResults on GroupSubscription {
        id
        name
        buyerCompanyId
        description
        pricePlan {
            ... on PricePlan {
                ...pricePlanResults
            }
        }
        recipientContactInformation {
            ... on GroupSubscriptionContactInformation {
                ...groupSubscriptionContactInformationResults
            }
        }
        subscriptionId
        totalSeats
        userSubscriptions
        createdAt
        updatedAt
        __typename
    }
    ${PRICE_PLAN_ATTRIBUTES}
    ${GROUP_SUBSCRIPTION_CONTACT_INFORMATION_ATTRIBUTES}
`;

export const GROUP_SUBSCRIPTION_DTO_ATTRIBUTES = gql`
    fragment groupSubscriptionDTOResults on GroupSubscriptionDTO {
        id
        company {
            name
        }
        description
        pricePlan {
            ... on PricePlan {
                ...pricePlanResults
            }
        }
        recipientContactInformation {
            ... on GroupSubscriptionContactInformation {
                ...groupSubscriptionContactInformationResults
            }
        }
        subscription {
            ... on Subscription {
                ...subscriptionResults
            }
        }
        totalSeats
        userSubscriptions
        __typename
    }
    ${PRICE_PLAN_ATTRIBUTES}
    ${SUBSCRIPTION_ATTRIBUTES}
    ${GROUP_SUBSCRIPTION_CONTACT_INFORMATION_ATTRIBUTES}
`;
