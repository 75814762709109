import gql from "graphql-tag";

export const KITCHEN_ORDERS_ATTRIBUTES = gql`
    fragment kitchenOrderProductsResults on KitchenOrderProduct {
        name
        comment
        hash
        quantity
        price
        refProductId
        refBundleProductId
        refProductCategoryId
        menuCategoryId
        addons {
            name
            quantity
            price
        }
        modifications {
            sizes {
                name
                addonPrice
            }
            flavours {
                name
                addonPrice
            }
        }
        selectedBundleProductItems {
            refProductId
            bundleProductCategoryId
            name
            hash
            kdsUnitDisplayName
            refProductCategoryId
            addons {
                name
                quantity
                price
            }
            modifications {
                sizes {
                    name
                    addonPrice
                }
                flavours {
                    name
                    addonPrice
                }
            }
        }
        __typename
    }
`;

export const KITCHEN_ORDER_ATTRIBUTES = gql`
    fragment kitchenOrderResults on KitchenOrder {
        id
        online
        onlineOrderStatus
        driveThrough
        postponePayment
        kitchenOrderProducts {
            ...kitchenOrderProductsResults
        }
        orderNo
        status
        cateringConfirmationDate
        pickupTime
        webOrderType
        orderType
        courierPickupTime
        arriveAtCustomerTime
        comment
        contactInformation {
            name
            lastName
            phoneNumber
            addressLine
            postCode
            doorCode
            city
            floorNumber
        }
        shopId
        takeAway
        homeDelivery
        eatingOption
        thirdPartyDeliveryType
        thirdPartyCode
        orderId
        orderIds
        paymentStatus
        orderStatusTimestamps {
            status
            onlineOrderStatus
            shopTableStatus
            timestamp
        }
        puckNo
        prepTime
        discountName
        tableName
        scheduledOrder
        topicIds
        autoAccepted
        denyReason
        __typename
    }
    ${KITCHEN_ORDERS_ATTRIBUTES}
`;
