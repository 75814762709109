import gql from "graphql-tag";

import { POS_ATTRIBUTES } from "../fragments";

export const UPDATE_OPENED_REGISTER_COUNT = gql`
    mutation incrementOpenedRegisterBox($posId: String) {
        incrementOpenedRegisterBox(posId: $posId)
    }
`;

export const UPSERT_POS = gql`
    mutation upsertPos($pos: POSInput!, $frontyVersion: String) {
        upsertPos(pos: $pos, frontyVersion: $frontyVersion) {
            ... on POS {
                ...POSResults
            }
        }
    }
    ${POS_ATTRIBUTES}
`;

export const ADD_CASH_CHANGE = gql`
    mutation addCashChange($posId: String, $dateTimeAmount: DateTimeAmountInput) {
        addCashChange(posId: $posId, dateTimeAmount: $dateTimeAmount)
    }
`;

export const DELETE_POS = gql`
    mutation deletePos($posId: String) {
        deletePos(posId: $posId)
    }
`;

export const PAIR_POS_WITH_DEVICE = gql`
    mutation pairPosWithDevice($posId: String, $cookieId: String) {
        pairPosWithDevice(posId: $posId, cookieId: $cookieId)
    }
`;

export const ADD_BUNDLE_TO_EXPRESS_CART = gql`
    mutation addBundleToExpressCart($menuBundleProduct: Object, $menuBundleProductItems: Object) {
        addBundleToExpressCart(menuBundleProduct: $menuBundleProduct, menuBundleProductItems: $menuBundleProductItems)
            @client
    }
`;

export const ADD_MENU_PRODUCT_TO_EXPRESS_CART = gql`
    mutation addMenuProductToExpressCart($menuProduct: Object) {
        addMenuProductToExpressCart(menuProduct: $menuProduct) @client
    }
`;

export const PAIR_EXPRESS_POS_WITH_DEVICE = gql`
    mutation pairExpressPosWithDevice($expressPosId: String, $deviceCookieId: String) {
        pairExpressPosWithDevice(expressPosId: $expressPosId, deviceCookieId: $deviceCookieId)
    }
`;
