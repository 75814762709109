import gql from "graphql-tag";
/* Graphql mutations: */

export const UPDATE_STATUS_AND_BATCH = gql`
    mutation updateStatusAndBatch($kitchenOrderId: String!, $isEnableKitchenDisplay: Boolean!) {
        updateStatusAndBatch(kitchenOrderId: $kitchenOrderId, isEnableKitchenDisplay: $isEnableKitchenDisplay)
    }
`;

export const CHANGE_KITCHEN_STATUS_ORDER = gql`
    mutation changeKitchenOrderStatus($orderId: String!, $status: String!, $isEnableKitchenDisplay: Boolean!) {
        changeKitchenOrderStatus(orderId: $orderId, status: $status, isEnableKitchenDisplay: $isEnableKitchenDisplay) {
            updatedAt
            id
            eatingOption
            tableName
            discountName
            puckNo
            online
            onlineOrderStatus
            orderId
            orderNo
            orderType
            status
            pickupTime
            comment
            shopId
            takeAway
            homeDelivery
            createdAt
            driveThrough
            postponePayment
            paymentStatus
            thirdPartyDeliveryType
            thirdPartyCode
            arriveAtCustomerTime
            courierPickupTime
            cateringConfirmationDate
            contactInformation {
                name
                lastName
                phoneNumber
                addressLine
                postCode
                doorCode
                city
                floorNumber
            }
            kitchenOrderProducts {
                name
                comment
                hash
                quantity
                price
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                addons {
                    name
                    quantity
                    price
                }
                modifications {
                    sizes {
                        name
                        addonPrice
                    }
                    flavours {
                        name
                        addonPrice
                    }
                }
                selectedBundleProductItems {
                    refProductId
                    bundleProductCategoryId
                    name
                    hash
                    kdsUnitDisplayName
                    refProductCategoryId
                    addons {
                        name
                        quantity
                        price
                    }
                    modifications {
                        sizes {
                            name
                            addonPrice
                        }
                        flavours {
                            name
                            addonPrice
                        }
                    }
                }
            }
            webOrderType
        }
    }
`;

export const UPDATE_ORDER_PRODUCT_QUANITY_IN_CART = gql`
    mutation updateOrderProductQuanityInCart($orderProduct: Object, $newQuantity: String) {
        updateOrderProductQuanityInCart(orderProduct: $orderProduct, newQuantity: $newQuantity) @client
    }
`;

export const REMOVE_ORDER_PRODUCT_FROM_CART = gql`
    mutation removeOrderProductFromCart($cartProductId: String) {
        removeOrderProductFromCart(cartProductId: $cartProductId) @client
    }
`;

export const CHANGE_KITCHEN_ORDER_PAYMENT_STATUS = gql`
    mutation changeKitchenOrderPaymentStatus($orderId: String) {
        changeKitchenOrderPaymentStatus(orderId: $orderId)
    }
`;
