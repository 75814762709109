import gql from "graphql-tag";

export const POS_ATTRIBUTES = gql`
    fragment POSResults on POS {
        id
        publicId
        name
        onlineExpress
        description
        shopId
        cookieId
        menuIds
        postponePaymentEnabled
        puckEnabled
        wasteOrderEnabled
        autoRotateMenus
        preferTakeAway
        preferDigitalReceipts
        connectedTerminalIds
        allowPhonePostponeOrders
        sameCardRefund
        posControlUnitValues {
            productionNumber
            controlUnitNumber
        }
        cashChangeInputs {
            dateTime
            amount
        }
        expressPOS {
            startScreenHeader
            startScreenBread
            startScreenBackgroundImgUrl
            startScreenBackgroundImgBlurred
            startScreenLogoImgUrl
            allowTableService
            allowLimitedPOSFeatures
        }
        receiptPrinter {
            ip
            deviceName
            usbLegacyPrinter
        }
        expressPOSVersion
        __typename
    }
`;
