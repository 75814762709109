import gql from "graphql-tag";

const INGREDIENT_LIMITERS_ATTRIBUTES = gql`
    fragment ingredientLimitersResults on IngredientLimiter {
        limit
        sortOrder
        ingredient {
            name
            price
        }
    }
`;

export const INGREDIENT_GROUP_ATTRIBUTES = gql`
    fragment ingredientGroupResults on IngredientGroup {
        id
        name
        limit
        sortOrder
        displayByName
        ingredientLimiters {
            ... on IngredientLimiter {
                ...ingredientLimitersResults
            }
        }
        thirdPartyServiceGroups {
            thirdPartyDeliveryType
            ingredientLimiters {
                ... on IngredientLimiter {
                    ...ingredientLimitersResults
                }
            }
        }
        samePricesForAllThirdPartyServiceGroups
        refProductIds
    }
    ${INGREDIENT_LIMITERS_ATTRIBUTES}
`;
