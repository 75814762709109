import gql from "graphql-tag";

export const PARTNER_PROMOTION_ATTRIBUTES = gql`
    fragment PartnerPromotionResults on PartnerPromotion {
        id
        partnerName
        headerImageUrl
        menuProductImageUrl
        partnerProductsImageUrl
        promotedCategoryImageUrl
        menuCategoryNavigationImageUrl
        companyLogoUrl
        __typename
    }
`;
