import gql from "graphql-tag";

export const CONNECT_SHOP_TO_FORTNOX = gql`
    mutation connectShopToFortnox(
        $accountAuthenticationSettings: AccountAuthenticationSettingsInput
        $authorizationCode: String
    ) {
        connectShopToFortnox(
            accountAuthenticationSettings: $accountAuthenticationSettings
            authorizationCode: $authorizationCode
        )
    }
`;
export const REMOVE_ACCOUNTING_ACCESS_TOKEN = gql`
    mutation removeAccountingAccessToken($shopId: String, $accountAuthenticationId: String) {
        removeAccountingAccessToken(shopId: $shopId, accountAuthenticationId: $accountAuthenticationId)
    }
`;

export const REMOVE_SHOP_FROM_AUTHENTICATIONS = gql`
    mutation removeShopIdFromAuthenticationAccounting($shopId: String, $accountAuthenticationIds: [String]) {
        removeShopIdFromAuthenticationAccounting(shopId: $shopId, accountAuthenticationIds: $accountAuthenticationIds)
    }
`;
