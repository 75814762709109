import gql from "graphql-tag";

import { FULL_DISCOUNT_ATTRIBUTES } from "../fragments";

export const GET_DISCOUNTS_OR_FIXED_DISCOUNTS = gql`
    query getDiscountsOrFixedDiscounts($companyId: String, $fixedDiscount: Boolean) {
        getDiscountsOrFixedDiscounts(companyId: $companyId, fixedDiscount: $fixedDiscount) {
            ... on Discount {
                ...discountResults
            }
        }
    }
    ${FULL_DISCOUNT_ATTRIBUTES}
`;

export const GET_VALID_DISCOUNTS = gql`
    query getValidDiscounts($companyId: String) {
        getValidDiscounts(companyId: $companyId) {
            ... on Discount {
                ...discountResults
            }
        }
    }
    ${FULL_DISCOUNT_ATTRIBUTES}
`;

export const GET_FIXED_DISCOUNT_BY_COMPANY_AND_SHOP_ID = gql`
    query getFixedDiscountByCompanyAndShopId($companyId: String, $shopId: String) {
        getFixedDiscountByCompanyAndShopId(companyId: $companyId, shopId: $shopId) {
            id
            companyId
            name
            code
            amount
            rate
            startDate
            endDate
            fixedDiscount
            openPriceRate
            preEnabled
            combineWithOtherDiscounts
            menuCategoryAndProductIds {
                menuId
                categoryIds
                productIds
            }
            onlineCustomerMessage
            shopIds
            eatingOptions
            disabled
        }
    }
`;

export const GET_COMPANY_SHOPS_AND_MENUS_FOR_DISCOUNT = gql`
    query getCompanyShopsAndMenusForDiscount($companyId: String) {
        getCompanyShopsForDiscounts(companyId: $companyId) {
            id
            name
            companyId
            menuIds
        }
        getCompanyMenusForDiscounts(companyId: $companyId) {
            id
            companyId
            name
            eatingOptions
            menuProductCategories {
                id
                name
                menuProducts {
                    id
                    refProduct {
                        id
                        name
                    }
                }
                menuBundleProducts {
                    id
                    refBundleProduct {
                        id
                        name
                    }
                }
            }
        }
    }
`;
