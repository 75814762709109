import gql from "graphql-tag";

import { SHOP_ATTRIBUTES } from "../fragments";

export const UPSERT_PUNCH_CLOCK_SHOPS = gql`
    mutation upsertPunchClockShops($shopIds: [String], $companyId: String) {
        upsertPunchClockShops(shopIds: $shopIds, companyId: $companyId) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const UPSERT_STAFF_MANAGEMENT_SHOPS = gql`
    mutation upsertStaffManagementSystem(
        $staffManagementSystem: [StaffManagementSystemAuthenticationInput]
        $companyId: String
        $managementType: StaffManagement
    ) {
        upsertStaffManagementSystem(
            staffManagementSystem: $staffManagementSystem
            companyId: $companyId
            managementType: $managementType
        ) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const VALIDATE_PERSONALKOLLEN_KEY = gql`
    mutation validatePersonalKollenAuthentication($apiKey: String) {
        validatePersonalKollenAuthentication(apiKey: $apiKey) {
            companyName
            description
        }
    }
`;

export const SEND_ORDER_TO_STAFF_MANAGEMENT = gql`
    mutation sendOrderToStaffManagementSystem($shopId: String, $orderId: String, $management: StaffManagement) {
        sendOrderToStaffManagementSystem(shopId: $shopId, orderId: $orderId, management: $management)
    }
`;
