import gql from "graphql-tag";

import { ACTIVE_HOUR_ATTRIBUTES, MENU_BUNDLE_PRODUCT_ATTRIBUTES, MENU_PRODUCT_ATTRIBUTES } from "./";

export const MENU_PRODUCT_CATEGORY_ATTRIBUTES = gql`
    fragment menuProductCategoryResults on MenuProductCategory {
        color
        createdAt
        deleted
        description
        disabled
        id
        imageUrl
        menuBundleProducts {
            ... on MenuBundleProduct {
                ...menuBundleProductResults
            }
        }
        menuProducts {
            ... on MenuProduct {
                ...menuProductResults
            }
        }
        name
        open
        posFavorite
        sortOrder
        updatedAt
        upsellCategory
        upsellCategoryAtPayment
        partnerPromotionImageType
    }
    ${MENU_BUNDLE_PRODUCT_ATTRIBUTES}
    ${MENU_PRODUCT_ATTRIBUTES}
`;

export const MENU_ATTRIBUTES = gql`
    fragment menuResults on Menu {
        activeHours {
            ... on ActiveHour {
                ...activeHourResults
            }
        }
        allowTopSeller
        companyId
        createdAt
        deleted
        description
        disabled
        eatingOptions
        expressStartMenu {
            header
            description
            expressStartMenuItemList {
                expressStartMenuItemType
                imageUrl
                refMenuItemId
            }
        }
        id
        isExpressMenu
        menuProductCategories {
            ... on MenuProductCategory {
                ...menuProductCategoryResults
            }
        }
        name
        rotate
        rotationGroupName
        sortOrder
        updatedAt
        menuChangelogs {
            username
            updatedAt
        }
    }
    ${ACTIVE_HOUR_ATTRIBUTES}
    ${MENU_PRODUCT_CATEGORY_ATTRIBUTES}
`;
