import gql from "graphql-tag";

export const GET_ALL_CHANGELOGS = gql`
    query getChangelogs {
        getAllChangelogs {
            id
            version
            createdAt
            message
        }
    }
`;

export const GET_LATEST_CHANGELOG = gql`
    query getLatestChangeLog {
        getLatestChangeLog {
            id
            version
            createdAt
            message
            deleted
        }
    }
`;
