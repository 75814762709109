import gql from "graphql-tag";

export const RETRY_THIRD_PARTY_ACCOUNTING_EXPORT = gql`
    mutation retryExport($shopId: String, $reportId: String, $isOnline: Boolean) {
        retryExport(shopId: $shopId, reportId: $reportId, isOnline: $isOnline)
    }
`;

export const RETRY_ALL_THIRD_PARTY_ACCOUNTING_EXPORT = gql`
    mutation retryExportAll($shopId: String, $isOnline: Boolean) {
        retryExportAll(shopId: $shopId, isOnline: $isOnline)
    }
`;
