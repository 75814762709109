import gql from "graphql-tag";

export const SET_GLOBAL_QOPLA_OBJECT = gql`
    mutation setGlobalQoplaObject($qopla: QoplaInput!) {
        setGlobalQoplaObject(qopla: $qopla) {
            id
            allShopsClosed {
                enabled
            }
            systemMessage {
                enabled
                messageId
            }
            closeAllExpress
        }
    }
`;

export const SET_QOPLA_MESSAGE = gql`
    mutation setQoplaMessage($shopMessage: ShopMessageInput!, $enabled: Boolean) {
        setQoplaMessage(shopMessage: $shopMessage, enabled: $enabled) {
            id
            title
            text
            type
            level
            shopIds
            email
            orderDisplay
            createdAt
            updatedAt
            activeFrom
            activeTo
        }
    }
`;

export const DISMISS_SHOP_QOPLA_MESSAGE = gql`
    mutation dismissShopQoplaMessage($shopMessage: ShopMessageInput!, $shopId: String!) {
        dismissShopQoplaMessage(shopMessage: $shopMessage, shopId: $shopId) {
            id
            title
            text
            type
            level
            shopIds
            dismissedShopIds
            email
            orderDisplay
            createdAt
            updatedAt
            activeFrom
            activeTo
        }
    }
`;
