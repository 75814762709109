import gql from "graphql-tag";

export const GET_COMBO_DISCOUNTS_BY_COMPANYID = gql`
    query getComboDiscounts($companyId: String!) {
        getComboDiscounts(companyId: $companyId) {
            id
            companyId
            name
            description
            shopIds
            startDate
            endDate
            staticPrice
            type
            disabled
            comboCategories {
                limit
                maxPerPurchase
                menuProductIds
            }
        }
    }
`;

export const GET_VALID_COMBO_DISCOUNTS = gql`
    query getAndValidateComboDiscount($companyId: String!, $shopId: String!) {
        getAndValidateComboDiscount(companyId: $companyId, shopId: $shopId) {
            id
            companyId
            name
            description
            shopIds
            startDate
            endDate
            staticPrice
            type
            canCombine
            comboCategories {
                limit
                maxPerPurchase
                menuProductIds
            }
        }
    }
`;