import gql from "graphql-tag";

import { CUSTOMER_FEEDBACK_ATTRIBUTES } from "../fragments";

export const UPSERT_CUSTOMER_FEEDBACK = gql`
    mutation upsertCustomerFeedback($customerFeedback: CustomerFeedbackInput) {
        upsertCustomerFeedback(customerFeedback: $customerFeedback) {
            ...customerFeedbackResults
        }
    }
    ${CUSTOMER_FEEDBACK_ATTRIBUTES}
`;