import gql from "graphql-tag";

export const CUSTOMER_FEEDBACK_ATTRIBUTES = gql`
    fragment customerFeedbackResults on CustomerFeedback {
        id
        shopId
        orderId
        name
        userAccountId
        overallRating
        feedback
        productRatings {
            name
            refProductId
            refBundleProductId
            bundleProductText
            thumbsUp
            feedback
        }
        additionalComments
        type
        qoplaReview {
            overallRating
            feedback
            additionalComments
        }
        createdAt
        __typename
    }
`;

export const SHOP_RATINGS_ATTRIBUTES = gql`
    fragment shopRatingsResults on Shop {
        id
        name
        shopKPIs {
            overallRatings {
                noOfReviews
                noOfFiveStars
                noOfFourStars
                noOfThreeStars
                noOfTwoStars
                noOfOneStars
            }
        }
        __typename
    }
`;
