import gql from "graphql-tag";

export const GIFT_CARD_ATTRIBUTES = gql`
    fragment giftCardResults on GiftCard {
        id
        companyId
        name
        description
        minAmount
        maxAmount
        imageUrl
        enabledForAllShops
        shopIds
        validForInMonths
        disabled
        deleted
        __typename
    }
`;
