import gql from "graphql-tag";

import { CUSTOMER_REGISTRATION_ATTRIBUTES } from "../fragments/customerRegistrationFragments";

export const GET_CUSTOMER_REGISTRATION_BY_ID = gql`
    query getCustomerRegistrationByIdQuery($id: String) {
        getCustomerRegistrationById(id: $id) {
            ... on CustomerRegistration {
                ...customerRegistrationResults
            }
        }
    }
    ${CUSTOMER_REGISTRATION_ATTRIBUTES}
`;

export const GET_CUSTOMER_REGISTRATIONS = gql`
    query getCustomerRegistrationsQuery {
        getCustomerRegistrations {
            ... on CustomerRegistration {
                ...customerRegistrationResults
            }
        }
    }
    ${CUSTOMER_REGISTRATION_ATTRIBUTES}
`;

export const GET_CUSTOMER_REGISTRATIONS_BY_STATUS_AND_DATE = gql`
    query getCustomerRegistrationsByStatusAndDate($statuses: [CustomerRegistrationStatus!], $fromDate: LocalDateTime) {
        getCustomerRegistrationsByStatusAndDate(statuses: $statuses, fromDate: $fromDate) {
            id
            updatedAt
            status
            companyName
            shopName
            registeredBy
            shopCreation
        }
    }
`;
