import gql from "graphql-tag";

export const UPSERT_INVOICE_CUSTOMER = gql`
    mutation upsertInvoiceCustomer($invoiceCustomer: InvoiceCustomerInput!) {
        upsertInvoiceCustomer(invoiceCustomer: $invoiceCustomer) {
            id
            shopId
            invoiceAddress {
                name
                addressLine
                postCode
                city
                phoneNumber
            }
            organisationNumber
            invoiceReference
            customerNumber
            contactPersons {
                name
                personalNumber
                phoneNumber
            }
        }
    }
`;

export const DELETE_INVOICE_CUSTOMER = gql`
    mutation deleteInvoiceCustomer($invoiceCustomerId: String!) {
        deleteInvoiceCustomer(invoiceCustomerId: $invoiceCustomerId)
    }
`;
