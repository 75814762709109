import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
    mutation uploadFile($base64Imgs: [Base64ImgDTOInput]) {
        uploadFile(base64Imgs: $base64Imgs)
    }
`;

export const UPLOAD_AND_RESIZE = gql`
    mutation resizeAndUploadImages($base64Imgs: [Base64ImgDTOInput]) {
        resizeAndUploadImages(base64Imgs: $base64Imgs)
    }
`;

export const DELETE_COMPANY_IMAGE = gql`
    mutation deleteFile($fileUrl: String) {
        deleteFile(fileUrl: $fileUrl)
    }
`;

export const COPY_IMAGES_FROM_COMPANY = gql`
    mutation copyImagesFromCompany($fromCompanyId: String, $toCompanyId: String) {
        copyImagesFromCompany(fromCompanyId: $fromCompanyId, toCompanyId: $toCompanyId)
    }
`;
