import gql from "graphql-tag";

export const GET_ALLERGENS = gql`
    query allAllergens {
        allAllergens {
            id
            name
            desc
            sortOrder
        }
    }
`;
