import gql from "graphql-tag";

import {
    COMPANY_AND_SHOPS_ATTRIBUTES,
    COMPANY_ATTRIBUTES,
    COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES,
    CONTACT_INFORMATION_ATTRIBUTES
} from "../fragments";

export const GET_ALL_COMPANY_LANDING_PAGES = gql`
    query getAllCompanyLandingPages {
        getAllCompanyLandingPages {
            publicId
            name
        }
    }
`;
export const GET_LANDING_PAGE = gql`
    query getLandingPage($subdomainName: String!) {
        getLandingPage(subdomainName: $subdomainName) {
            id
            landingPageType
            groupLandingPage {
                landingPage {
                    landingPageEnabled
                    backgroundImageUrl
                    headerText
                    headerTextColor
                    breadText
                    breadTextColor
                    expressOnline
                }
                sortedPublicShopIds {
                    id
                    sortNumber
                }
                sortedCompanyLandingPageIds {
                    id
                    sortNumber
                }
                name
                logoUrl
                facebook
                instagram
                tiktok
            }
            groupShops {
                publicId
                name
                desc
                companyId
                contactInformation {
                    addressLine
                    postCode
                    city
                    country
                    phoneNumber
                    email
                }
                imageUrl
                bannerUrl
                activeHours {
                    dayOfWeek
                    startingHour
                    stoppingHour
                }
                settings {
                    onlineSettings {
                        closeOnlineOrders
                        customClosedMessage
                        enableExpressOnline
                        expressOnlineMenuIds
                        expressOnlineTextColor
                    }
                }
            }
            groupCompanyOnlineLandingPages {
                publicId
                settings {
                    companyOnlineLandingPage {
                        subdomainName
                        headerText
                        backgroundImageUrl
                        breadText
                        groupLandingPageCardName
                        groupLandingPageCardDescription
                    }
                }
                countryLocale {
                    localeCode
                    currencyCode
                }
            }
        }
    }
`;

export const GET_SUPER_ADMIN_COMPANIES = gql`
    query getSuperAdminCompanyList {
        getSuperAdminCompanyList {
            company {
                id
                name
                vatRates
                updateChangeNumber
                contactInformation {
                    city
                    name
                }
                settings {
                    posEnabled
                    onlineEnabled
                    stockTrackingEnabled
                    sellingAlcohol
                    thirdPartyAccountingAccessType
                    companySubscriptionSettings {
                        friendlySubdomainName
                        title
                        description
                        vatRate
                        imageUrl
                    }
                }
            }
            countryLocale {
                localeCode
                currencyCode
            }
            contactMail
            organisationNumber
            shopNames
            pendingRegistration
            hasPos
            isUmbrellaCompany
        }
    }
`;

export const GET_SHOPS_BY_SHOP_IDS_OR_COMPANY_IDS = gql`
    query getShopsByShopIdsOrCompanyIds($ids: [String]) {
        getShopsByShopIdsOrCompanyIds(ids: $ids) {
            ... on CompanyAndShops {
                ...companyAndShopsResults
            }
        }
    }
    ${COMPANY_AND_SHOPS_ATTRIBUTES}
`;

export const GET_COMPANY_NAME_BY_ID = gql`
    query getCompanyNameById($companyId: String!) {
        getCompanyNameById(companyId: $companyId)
    }
`;

export const GET_COMPANY_BY_PUBLIC_ID = gql`
    query getCompanyByPublicId($publicId: String!) {
        getCompanyByPublicId(publicId: $publicId) {
            imageUrl
            name
            email
            contactInformation {
                ... on ContactInformation {
                    ...contactInformationResults
                }
            }
            countryLocale {
                localeCode
            }
            shops {
                publicId
                name
                desc
                companyId
                contactInformation {
                    addressLine
                    postCode
                    city
                    country
                    phoneNumber
                    email
                }
                imageUrl
                bannerUrl
                activeHours {
                    dayOfWeek
                    startingHour
                    stoppingHour
                }
                settings {
                    onlineSettings {
                        closeOnlineOrders
                        customClosedMessage
                    }
                }
            }
            companyOnlineLandingPage {
                landingPageEnabled
                backgroundImageUrl
                headerText
                headerTextColor
                breadText
                breadTextColor
            }
            companyOnlineSettings {
                facebook
                instagram
            }
        }
    }
    ${CONTACT_INFORMATION_ATTRIBUTES}
`;

export const GET_COMPANY_SUBSCRIPTION_SETTINGS = gql`
    query getCompanySubscriptionSettingsByIdQuery($companyId: String!) {
        getCompanySubscriptionSettingsById(companyId: $companyId) {
            ...companySubscriptionSettingsResults
        }
    }
    ${COMPANY_SUBSCRIPTIONS_SETTINGS_ATTRIBUTES}
`;

export const GET_COMPANY_BY_ID = gql`
    query getCompanyById($companyId: String!) {
        getCompanyById(companyId: $companyId) {
            ...companyResults
        }
    }
    ${COMPANY_ATTRIBUTES}
`;

export const GET_COMPANY_SHOPS_MENU_IDS = gql`
    query companyShopMenuIds($companyId: String!) {
        getCompanyShops(companyId: $companyId) {
            id
            name
            menuIds
            settings {
                partnerPromotionId
            }
        }
    }
`;

export const VALIDATE_COMPANY_EMAIL = gql`
    query validateCompanyEmail($email: String!) {
        validateCompanyEmail(email: $email) {
            email
        }
    }
`;

export const VALIDATE_ORGNR = gql`
    query validateOrganisationNumber($organisationNumber: String!) {
        validateOrganisationNumber(organisationNumber: $organisationNumber)
    }
`;

export const GET_COMPANY_AND_SHOP_UPDATE_CHANGE_NUMBER = gql`
    query getCompanyAndShopUpdateChangeNumber($companyId: String, $shopId: String) {
        getCompanyUpdateChangeNumber(companyId: $companyId)
        getShopUpdateChangeNumber(shopId: $shopId)
    }
`;
export const GET_ALL_COMPANY_IMAGES = gql`
    query getAllCompanyImages($companyId: String) {
        getAllCompanyImages(companyId: $companyId)
    }
`;

export const GET_PUBLIC_COMPANY_ONLINE_SETTINGS = gql`
    query getPublicCompanyOnlineSettings($companyId: String) {
        getPublicCompanyOnlineSettings(companyId: $companyId) {
            settings {
                companyOnlineSettings {
                    facebook
                    instagram
                }
            }
        }
    }
`;

export const GET_COMPANY_FRIENDLY_SUBDOMAIN_BY_USER_SUBSCRIPTION_ID = gql`
    query getCompanyFriendlySubdomainNameByUserSubscriptionIdQuery($userSubscriptionId: String) {
        getCompanyFriendlySubdomainNameByUserSubscriptionId(userSubscriptionId: $userSubscriptionId)
    }
`;

export const GET_COMPANIES_FOR_SELECT = gql`
    query getCompaniesForSelect {
        getCompaniesForSelect {
            value: id
            label: name
        }
    }
`;

export const GET_UMBRELLA_COMPANIES_BY_IDS = gql`
    query getUmbrellaCompaniesByIds($umbrellaAdminId: String) {
        getUmbrellaCompaniesByIds(umbrellaAdminId: $umbrellaAdminId) {
            company {
                id
                name
                vatRates
                updateChangeNumber
                contactInformation {
                    city
                }
                settings {
                    posEnabled
                    onlineEnabled
                    companySubscriptionSettings {
                        friendlySubdomainName
                        title
                        description
                        vatRate
                        imageUrl
                    }
                }
            }
            countryLocale {
                localeCode
                currencyCode
            }
            contactMail
            organisationNumber
            shopNames
            pendingRegistration
            hasPos
            isUmbrellaCompany
        }
    }
`;

export const VALIDATE_REAL_TIME_DATA_CONNECTION = gql`
    query validateRealTimeDataConnection($realTimeDataConnection: RealTimeDataConnectionInput, $companyId: String) {
        validateRealTimeDataConnection(realTimeDataConnection: $realTimeDataConnection, companyId: $companyId)
    }
`;
