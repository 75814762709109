import gql from "graphql-tag";

import { GROUP_SUBSCRIPTION_ATTRIBUTES, SUBSCRIPTION_ATTRIBUTES, USER_SUBSCRIPTION_ATTRIBUTES } from "../fragments";

export const UPSERT_SUBSCRIPTION = gql`
    mutation upsertSubscriptionMutation($subscription: SubscriptionInput) {
        upsertSubscription(subscription: $subscription) {
            ... on Subscription {
                ...subscriptionResults
            }
        }
    }
    ${SUBSCRIPTION_ATTRIBUTES}
`;

export const UPSERT_GROUP_SUBSCRIPTION = gql`
    mutation upsertGroupSubscriptionMutation($groupSubscription: GroupSubscriptionInput) {
        upsertGroupSubscription(groupSubscription: $groupSubscription) {
            ... on GroupSubscription {
                ...groupSubscriptionResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_ATTRIBUTES}
`;

export const CANCEL_GROUP_SUBSCRIPTION = gql`
    mutation cancelGroupSubscriptionMutation($groupSubscriptionId: String) {
        cancelGroupSubscription(groupSubscriptionId: $groupSubscriptionId) {
            ... on GroupSubscription {
                ...groupSubscriptionResults
            }
        }
    }
    ${GROUP_SUBSCRIPTION_ATTRIBUTES}
`;

export const ACCEPT_GROUP_SUBSCRIPTION_INVITATION = gql`
    mutation acceptGroupSubscriptionInvitation($groupSubscriptionOrderDTO: GroupSubscriptionOrderDTOInput) {
        acceptGroupSubscriptionInvitation(groupSubscriptionOrderDTO: $groupSubscriptionOrderDTO) {
            ... on UserSubscriptionDTO {
                ...userSubscriptionResults
            }
        }
    }
    ${USER_SUBSCRIPTION_ATTRIBUTES}
`;

export const CANCEL_USER_SUBSCRIPTION = gql`
    mutation cancelUserSubscription(
        $userSubscriptionId: String
        $subscriptionCancelReason: SubscriptionCancelReasonInput
    ) {
        cancelUserSubscription(
            userSubscriptionId: $userSubscriptionId
            subscriptionCancelReason: $subscriptionCancelReason
        )
    }
`;

export const RESUME_USER_SUBSCRIPTION = gql`
    mutation resumeUserSubscription($userSubscriptionId: String) {
        resumeUserSubscription(userSubscriptionId: $userSubscriptionId)
    }
`;

export const UPGRADE_USER_SUBSCRIPTION = gql`
    mutation upgradeUserSubscription($userSubscriptionId: String, $targetSubscriptionId: String) {
        upgradeUserSubscription(userSubscriptionId: $userSubscriptionId, targetSubscriptionId: $targetSubscriptionId) {
            order {
                id
            }
            webPaymentResponse {
                operationSuccess
                token
                transactionId
                redirectUrl
                errorText
            }
        }
    }
`;

export const PROCESS_USER_SUBSCRIPTION_UPGRADE_PAYMENT = gql`
    mutation processUserSubscriptionUpgradePayment(
        $userSubscriptionId: String
        $targetSubscriptionId: String
        $orderId: String
    ) {
        processUserSubscriptionUpgradePayment(
            userSubscriptionId: $userSubscriptionId
            targetSubscriptionId: $targetSubscriptionId
            orderId: $orderId
        ) {
            webPaymentResponse {
                operationSuccess
                errorText
            }
            order {
                shopId
                paymentStatus
            }
        }
    }
`;

export const ADD_USER_ACCOUNT_SUBSCRIPTION = gql`
    mutation addUserSubscriptionMutation($subscriptionOrderDTO: SubscriptionOrderDTOInput) {
        addUserSubscription(subscriptionOrderDTO: $subscriptionOrderDTO) {
            order {
                id
            }
            webPaymentResponse {
                operationSuccess
                token
                transactionId
                redirectUrl
                errorText
            }
        }
    }
`;

export const PROCESS_SUBSCRIPTION_PAYMENT = gql`
    mutation processUserSubscriptionPayment($userSubscriptionId: String, $orderId: String) {
        processUserSubscriptionPayment(userSubscriptionId: $userSubscriptionId, orderId: $orderId) {
            webPaymentResponse {
                operationSuccess
                errorText
            }
            order {
                shopId
                paymentStatus
            }
        }
    }
`;

export const CANCEL_USER_SUBSCRIPTION_NOW = gql`
    mutation cancelUserSubscriptionNow(
        $userSubscriptionId: String
        $subscriptionCancelReason: SubscriptionCancelReasonInput
    ) {
        cancelUserSubscriptionNow(
            userSubscriptionId: $userSubscriptionId
            subscriptionCancelReason: $subscriptionCancelReason
        )
    }
`;

export const CANCEL_USER_SUBSCRIPTION_FROM_GROUP = gql`
    mutation cancelUserFromGroupSubscription(
        $subscriptionId: String
        $userSubscriptionId: String
        $subscriptionCancelReason: SubscriptionCancelReasonInput
    ) {
        cancelUserFromGroupSubscription(
            subscriptionId: $subscriptionId
            userSubscriptionId: $userSubscriptionId
            subscriptionCancelReason: $subscriptionCancelReason
        )
    }
`;

export const CANCEL_PENDING_USER_SUBSCRIPTION_PAYMENT = gql`
    mutation cancelPendingUserSubscriptionPayment($userSubscriptionId: String, $orderId: String) {
        cancelPendingUserSubscriptionPayment(userSubscriptionId: $userSubscriptionId, orderId: $orderId)
    }
`;
