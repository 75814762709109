import gql from "graphql-tag";

export const GET_INVOICE_CUSTOMERS_BY_SHOP_ID = gql`
    query getShopInvoiceCustomers($shopId: String) {
        getShopInvoiceCustomers(shopId: $shopId) {
            id
            shopId
            invoiceAddress {
                name
                addressLine
                postCode
                city
                phoneNumber
            }
            organisationNumber
            invoiceReference
            customerNumber
            contactPersons {
                name
                personalNumber
                phoneNumber
            }
        }
    }
`;
