import gql from "graphql-tag";

export const FULL_DISCOUNT_ATTRIBUTES = gql`
    fragment discountResults on Discount {
        id
        companyId
        name
        code
        amount
        rate
        startDate
        endDate
        fixedDiscount
        openPriceRate
        preEnabled
        maxRedeemed
        noRedeemed
        oneEmailLimit
        combineWithOtherDiscounts
        menuCategoryAndProductIds {
            menuId
            categoryIds
            productIds
        }
        onlineCustomerMessage
        shopIds
        eatingOptions
        canApplyOnEachProduct
        canApplyOnWholeOrder
        emailOnlyDiscount
        allowedEmails
        availableOn
        disabled
        __typename
    }
`;

export const DISCOUNT_META_ATTRIBUTES = gql`
    fragment discountMetaResults on DiscountMeta {
        id
        discounts {
            discountId
            name
            code
            discountValue
        }
        name
        code
        amount
        rate
        originalPrice
        totalDiscountValue
        canApplyOnEachProduct
        canApplyOnWholeOrder
        appliedDiscountType
        emailOnlyDiscount
        allowedEmails
        availableOn
        campaignId
        __typename
    }
`;

export const COMBO_DISCOUNT_ATTRIBUTES = gql`
    fragment comboDiscountResults on ComboDiscount {
        companyId
        name
        description
        type
        staticPrice
        startDate
        endDate
        enabled
        shopIds
        comboCategories {
            limit
            maxPerPurchase
            menuProductIds
        }
        canCombine
        wasApplied
        __typename
    }
`;
