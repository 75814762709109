import gql from "graphql-tag";
import { COMBO_DISCOUNT_ATTRIBUTES, DISCOUNT_META_ATTRIBUTES, CART_PRODUCT_ATTRIBUTES } from ".";

export const ORDER_WINDOW_CONTACT_INFORMATION_ATTRIBUTES = gql`
    fragment orderWindowContactInformationResults on OrderWindowContactInformation {
        name
        phoneNumber
        pickupTime
        comment
        __typename
    }
`;

export const ORDER_WINDOW_ATTRIBUTES = gql`
    fragment orderWindowResults on OrderWindow {
        id
        displayName
        postponePayment
        puckNo
        postponeOrderId
        shopId
        shouldHide
        discount {
            ... on DiscountMeta {
                ...discountMetaResults
            }
        }

        comboDiscounts {
            ... on ComboDiscount {
                ...comboDiscountResults
            }
        }

        cartProducts {
            ... on CartProduct {
                ...cartProductResults
            }
        }
        discountedProductIds
        takeAway
        previousCartProducts {
            ... on CartProduct {
                ...cartProductResults
            }
        }
        contactInformation {
            ... on OrderWindowContactInformation {
                ...orderWindowContactInformationResults
            }
        }
        paymentStatus
        shopTableStatuses {
            status
            onlineOrderStatus
            shopTableStatus
            timestamp
        }
        comboDiscountedProductIds
        tableId
        discountedIdsAndQuantity {
            cartId
            discountType
            quantity
        }
        deleted
        fromSplitOrderWindowId
        isFromSocket
        updatedAt
        orderNo
        __typename
    }
    ${DISCOUNT_META_ATTRIBUTES}
    ${COMBO_DISCOUNT_ATTRIBUTES}
    ${CART_PRODUCT_ATTRIBUTES}
    ${ORDER_WINDOW_CONTACT_INFORMATION_ATTRIBUTES}
`;
