import gql from "graphql-tag";

import { GIFT_CARD_ATTRIBUTES } from "../fragments/giftCardFragments";

export const UPSERT_GIFTCARD_SETTINGS = gql`
    mutation upsertGiftCardMutation($giftCard: GiftCardInput) {
        upsertGiftCard(giftCard: $giftCard) {
            ...giftCardResults
        }
    }
    ${GIFT_CARD_ATTRIBUTES}
`;
