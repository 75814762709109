import gql from "graphql-tag";
export const ZX_REPORT_ATTRIBUTES = gql`
    fragment ZXReportResults on ZXReport {
        id
        cashInvoicesExists
        invoicePaymentExists
        dateIntervalReport
        posName
        createdBy
        createdAt
        startDate
        endDate
        shopId
        shopName
        organisationNumber
        refundTotalVatAmount
        contactInformation {
            addressLine
            postCode
            city
            country
            phoneNumber
        }
        reportNumber
        reportType
        categoryTotalSales {
            categoryName
            totalSales
        }
        paymentMethodAndAmounts {
            paymentMethod
            amount
            tip
        }
        paymentMethodAndAmountsOnline {
            paymentMethod
            amount
            tip
        }
        cardAmounts {
            cardType
            amount
            tip
        }
        cardAmountsOnline {
            cardType
            amount
            tip
        }
        vatRatesAndAmounts {
            vatRate
            amount
        }
        vatRateAmountWithRefundsOnline {
            vatRate
            amount
            refundedAmount
        }
        vatRateAmountWithRefunds {
            vatRate
            amount
            refundedAmount
        }
        posIngoingCashChange {
            posId
            amount
            posName
        }
        sumSoldProducts
        sumReceipts
        sumOpenedRegister
        copies {
            receiptType
            count
            amount
        }
        tests {
            receiptType
            count
            amount
        }
        refunds {
            receiptType
            count
            amount
        }
        discounts {
            receiptType
            count
            amount
        }
        otherExpenses {
            receiptType
            count
            amount
        }
        unfinished {
            receiptType
            count
            amount
        }
        roundedCashDecimals
        totalSales
        totalNetSales
        totalSalesOnline
        totalNetSalesOnline
        grandTotalSales
        grandTotalRefund
        grandTotalNet
        cashInvoiceSummaries {
            verificationNumber
            totalSales
            totalNetSales
            vatRatesAndAmounts {
                vatRate
                amount
            }
            paymentMethodAmounts {
                paymentMethod
                amount
            }
        }
        tip
        hasSuccessfulSIEFile
        hasSuccessfulThirdPartyExport
        __typename
    }
`;
