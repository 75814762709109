import gql from "graphql-tag";

export const COPY_MENU = gql`
    mutation copyMenu($menuId: String!, $menuName: String!) {
        copyMenu(menuId: $menuId, menuName: $menuName) {
            id
            name
            sortOrder
            rotate
            rotationGroupName
            description
            disabled
            eatingOptions
            allowTopSeller
            companyId
            thirdPartyDeliveryTypes
            menuLocks{
                lockedBy
                locked
                lockedEndDate
                lockComment
                servicesToLock
            }
            deleted
            updatedAt
            activeHours {
                dayOfWeek
            }
            menuChangelogs {
                username
                updatedAt
            }
        }
    }
`;

export const DELETE_MENU = gql`
    mutation deleteMenu($menuId: String!) {
        deleteMenu(menuId: $menuId)
    }
`;

export const RESTORE_MENU = gql`
    mutation restoreMenu($menuId: String!) {
        restoreMenu(menuId: $menuId)
    }
`;

export const TOGGLE_MENU_DISABLED = gql`
    mutation toggleMenuDisabled($menuId: String!) {
        toggleMenuDisabled(menuId: $menuId)
    }
`;

export const UPDATE_MENU = gql`
    mutation upsertMenu($menu: MenuInput!) {
        upsertMenu(menu: $menu) {
            id
            companyId
            name
            sortOrder
            rotate
            rotationGroupName
            description
            disabled
            eatingOptions
            allowTopSeller
            activeHours {
                dayOfWeek
            }
            thirdPartyDeliveryTypes
            menuLocks{
                lockedBy
                locked
                lockedEndDate
                lockComment
                servicesToLock
            }
            menuChangelogs {
                username
                updatedAt
            }
            deleted
            updatedAt
        }
    }
`;
