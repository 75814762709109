import gql from "graphql-tag";

export const ADD_TERMINAL = gql`
    mutation addTerminal($terminal: TerminalInput!) {
        addTerminal(terminal: $terminal) {
            id
            terminalId
            shopId
            name
            description
        }
    }
`;

export const CLEAR_REDIS_KDS = gql`
    mutation deleteAllKDSPing {
        deleteAllKDSPing
    }
`

export const EVICT_ALL_CACHES = gql`
    mutation evictAllCaches {
        evictAllCaches
    }
`;

export const EVICT_TERMINAL_CACHE = gql`
    mutation evictTerminalCache {
        evictTerminalCache
    }
`;

export const EVICT_USER_CACHE = gql`
    mutation evictCompanyUserCache {
        evictCompanyUserCache
    }
`;
