import gql from "graphql-tag";

export const CREDIT_CARD_ATTRIBUTES = gql`
    fragment creditCardInfoResults on CreditCardInfo {
        cardIssuer
        cardNumber
        transactionId
        countryName
        cardType
        cardProductType
        countryCode
        maskedPAN
        PANHash
        __typename
    }
`;

export const ACTIVE_HOUR_ATTRIBUTES = gql`
    fragment activeHourResults on ActiveHour {
        closed
        date
        dayOfWeek
        desc
        holiday
        startingHour
        stoppingHour
        __typename
    }
`;

export const CONTACT_INFORMATION_ATTRIBUTES = gql`
    fragment contactInformationResults on ContactInformation {
        name
        lastName
        email
        addressLine
        addressLine2
        postCode
        city
        country
        other
        phoneNumber
        phoneNumber2
        personalNumber
        floorNumber
        doorCode
        latLng {
            lat
            lng
        }
        __typename
    }
`;


export const SERVICE_DISRUPTION_ATTRIBUTES = gql`
    fragment serviceDisruptionResults on ServiceDisruption {
        id
        serviceDisrupted
        typeOfServiceDisruption
        setOnAllShops
        __typename
    }
`;