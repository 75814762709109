import gql from "graphql-tag";
import { ZX_REPORT_ATTRIBUTES } from "../fragments";

export const RUN_MISSING_REPORTS = gql`
    mutation recreateMissingReportsOperation($date: String) {
        recreateMissingReportsOperation(date: $date)
    }
`;

export const RUN_MISSING_Z_REPORTS = gql`
    mutation recreateMissingZReportsOperation($date: String) {
        recreateMissingZReportsOperation(date: $date)
    }
`;

export const CREATE_WEB_INVOICE_TEST = gql`
    mutation createWebOrderInvoiceReport($shopId: String) {
        createWebOrderInvoiceReport(shopId: $shopId) {
            invoiceNumber
        }
    }
`;

export const CREATE_MONTHLY_WEB_INVOICE_TEST = gql`
    mutation createMonthlyWebOrderInvoiceReport($shopId: String) {
        createMonthlyWebOrderInvoiceReport(shopId: $shopId) {
            invoiceNumber
        }
    }
`;

export const CREATE_PRODUCT_COUNT_REPORT = gql`
    mutation createShopProductCountAmountReport($shopId: String, $startDate: String, $endDate: String) {
        createShopProductCountAmountReport(shopId: $shopId, startDate: $startDate, endDate: $endDate) {
            domainId
            domainName
            domainType
            contentCountAndAmount {
                contentId
                contentName
                contentTypeName
                count
                amount
            }
            children {
                domainId
                domainName
                domainType
                contentCountAndAmount {
                    contentId
                    contentName
                    contentTypeName
                    count
                    amount
                }
            }
        }
    }
`;

export const CREATE_CASH_INVOICE_REPORT = gql`
    mutation createCashInvoiceReport($shopId: String!, $startDate: String, $endDate: String) {
        createCashInvoiceReport(shopId: $shopId, startDate: $startDate, endDate: $endDate) {
            ... on ZXReport {
                ...ZXReportResults
            }
        }
    }
    ${ZX_REPORT_ATTRIBUTES}
`;

export const CREATE_ZX_DATE_REPORT_FOR_SHOP = gql`
    mutation createDateXReportForShop(
        $shopId: String!
        $reportType: ReportType
        $companyUserName: String
        $startDate: String
        $endDate: String
    ) {
        createDateXReportForShop(
            shopId: $shopId
            reportType: $reportType
            companyUserName: $companyUserName
            startDate: $startDate
            endDate: $endDate
        ) {
            id
            cashInvoicesExists
            invoicePaymentExists
            dateIntervalReport
            posName
            createdBy
            createdAt
            startDate
            endDate
            shopId
            shopName
            organisationNumber
            refundTotalVatAmount
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
            }
            reportNumber
            reportType
            categoryTotalSales {
                categoryName
                totalSales
            }
            paymentMethodAndAmounts {
                paymentMethod
                amount
                tip
            }
            paymentMethodAndAmountsOnline {
                paymentMethod
                amount
                tip
            }
            cardAmounts {
                cardType
                amount
                tip
            }
            cardAmountsOnline {
                cardType
                amount
                tip
            }
            vatRateAmountWithRefundsOnline {
                vatRate
                amount
                refundedAmount
            }
            vatRateAmountWithRefunds {
                vatRate
                amount
                refundedAmount
            }
            vatRatesAndAmounts {
                vatRate
                amount
            }
            posIngoingCashChange {
                posId
                amount
                posName
            }
            sumSoldProducts
            sumReceipts
            sumOpenedRegister
            copies {
                receiptType
                count
                amount
            }
            tests {
                receiptType
                count
                amount
            }
            refunds {
                receiptType
                count
                amount
            }
            discounts {
                receiptType
                count
                amount
            }
            otherExpenses {
                receiptType
                count
                amount
            }
            unfinished {
                receiptType
                count
                amount
            }
            roundedCashDecimals
            totalSales
            totalNetSales
            totalSalesOnline
            totalNetSalesOnline
            grandTotalSales
            grandTotalRefund
            grandTotalNet
            cashInvoiceSummaries {
                verificationNumber
                totalSales
                totalNetSales
                vatRatesAndAmounts {
                    vatRate
                    amount
                }
            }
            hasSuccessfulSIEFile
            tip
            hasSuccessfulThirdPartyExport
        }
    }
`;

export const CREATE_ZX_REPORT_FOR_SHOP = gql`
    mutation createZReportForShop($shopId: String!, $reportType: ReportType, $companyUserName: String) {
        createZXReportForShop(shopId: $shopId, reportType: $reportType, companyUserName: $companyUserName) {
            id
            cashInvoicesExists
            invoicePaymentExists
            dateIntervalReport
            posName
            createdBy
            createdAt
            startDate
            endDate
            shopId
            shopName
            organisationNumber
            refundTotalVatAmount
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
            }
            reportNumber
            reportType
            categoryTotalSales {
                categoryName
                totalSales
            }
            paymentMethodAndAmounts {
                paymentMethod
                amount
                tip
            }
            paymentMethodAndAmountsOnline {
                paymentMethod
                amount
                tip
            }
            cardAmounts {
                cardType
                amount
            }
            cardAmountsOnline {
                cardType
                amount
                tip
            }
            vatRateAmountWithRefundsOnline {
                vatRate
                amount
                refundedAmount
            }
            vatRateAmountWithRefunds {
                vatRate
                amount
                refundedAmount
            }
            vatRatesAndAmounts {
                vatRate
                amount
            }
            posIngoingCashChange {
                posId
                amount
                posName
            }
            sumSoldProducts
            sumReceipts
            sumOpenedRegister
            copies {
                receiptType
                count
                amount
            }
            tests {
                receiptType
                count
                amount
            }
            refunds {
                receiptType
                count
                amount
            }
            discounts {
                receiptType
                count
                amount
            }
            otherExpenses {
                receiptType
                count
                amount
            }
            unfinished {
                receiptType
                count
                amount
            }
            roundedCashDecimals
            totalSales
            totalNetSales
            totalSalesOnline
            totalNetSalesOnline
            grandTotalSales
            grandTotalRefund
            grandTotalNet
            cashInvoiceSummaries {
                verificationNumber
                totalSales
                totalNetSales
                vatRatesAndAmounts {
                    vatRate
                    amount
                }
            }
            tip
            hasSuccessfulSIEFile
            hasSuccessfulThirdPartyExport
        }
    }
`;
