import { ShopTags } from "Types";
import gql from "graphql-tag";

export namespace ShopTagMutations {
    export type GenerateShopTags = {
        generateShopTags: string[];
    };
    export type UpsertShopTags = {
        upsertShopTags: ShopTags;
    };
}
export const GENERATE_SHOP_TAGS = gql`
    mutation generateShopTags($shopId: String) {
        generateShopTags(shopId: $shopId)
    }
`;

export const UPSERT_SHOP_TAGS = gql`
    mutation upsertShopTags($shopId: String, $shopTags: ShopTagsInput) {
        upsertShopTags(shopId: $shopId, shopTags: $shopTags) {
            generated
            always
            never
        }
    }
`;
