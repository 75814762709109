import gql from "graphql-tag";

import { ERROR_ATTRIBUTES, USER_ACCOUNT_ATTRIBUTES } from "../fragments";

export const LOGIN = gql`
    mutation login($credentials: CredentialsInput) {
        login(userCredentials: $credentials) {
            userId
            username
            roles
            companyId
            shopIds
            token
            fullName
            umbrellaCompanyIds
        }
        ttlTimeoutMs
    }
`;

export const LOGIN_USER_ACCOUNT_MUTATION = gql`
    mutation loginUserAccountMutation($input: LoginInput!) {
        loginUserAccount(input: $input) {
            ... on Error {
                ...errorResults
            }

            ... on UserAccount {
                ...userAccountResults
            }
        }
    }
    ${ERROR_ATTRIBUTES}
    ${USER_ACCOUNT_ATTRIBUTES}
`;

export const GOOGLE_SIGN_IN = gql`
    mutation googleSignin($input: GoogleAuthInput!) {
        googleSignin(input: $input) {
            ... on Error {
                ...errorResults
            }

            ... on UserAccount {
                ...userAccountResults
            }
        }
    }
    ${ERROR_ATTRIBUTES}
    ${USER_ACCOUNT_ATTRIBUTES}
`;

export const LOGOUT = gql`
    mutation logout($username: String) {
        logout(username: $username)
    }
`;

export const HEARTBEAT = gql`
    mutation heartBeat {
        heartBeat
    }
`;

export const PING_CONTROL_UNIT = gql`
    mutation pingControlUnit($shopId: String, $companyId: String) {
        pingControlUnit(shopId: $shopId, companyId: $companyId) {
            mothershipAlive
            controlUnitAlive
            shopUpdateChangeNumber
            companyUpdateChangeNumber
            buildNumber
        }
    }
`;

export const PING_BACKEND = gql`
    mutation pingBackend($shopId: String, $companyId: String, $onlineEnabled: Boolean) {
        pingBackend(shopId: $shopId, companyId: $companyId, onlineEnabled: $onlineEnabled) {
            mothershipAlive
            shopUpdateChangeNumber
            companyUpdateChangeNumber
            buildNumber
            qopla {
                allShopsClosed {
                    enabled
                }
                systemMessage {
                    enabled
                    messageId
                }
            }
            globalQoplaMessage {
                id
                title
                text
                type
                level
            }
            shopQoplaMessage {
                id
                title
                text
                type
                level
                shopIds
                orderDisplay
            }
        }
    }
`;

export const COMPANY_USER_FORGOT_PASSWORD = gql`
    mutation companyUserForgotPasswordMutation($email: String, $language: String) {
        companyUserForgotPassword(email: $email, language: $language)
    }
`;

export const CAN_GET_COMPANY_USER_BY_TOKEN = gql`
    query canGetCompanyUserByTokenQuery($token: String) {
        canGetCompanyUserByToken(token: $token)
    }
`;

export const RESET_COMPANY_USER_PASSWORD = gql`
    mutation resetCompanyUserPasswordMutation($token: String, $newPassword: String) {
        resetCompanyUserPassword(token: $token, newPassword: $newPassword)
    }
`;

export const CREATE_COMPANY_USER_RESET_PASSWORD_LINK = gql`
    mutation createCompanyUserResetPasswordLinkMutation($email: String) {
        createCompanyUserResetPasswordLink(email: $email)
    }
`;
