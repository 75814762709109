import gql from "graphql-tag";

export const UPDATE_BAXI_CLOUD_TOKENS = gql`
    mutation getNewBaxiCloudTokens {
        getNewBaxiCloudTokens
    }
`;

export const UPSERT_TERMINAL = gql`
    mutation upsertTerminal($terminal: TerminalInput!) {
        upsertTerminal(terminal: $terminal) {
            id
            terminalId
            shopId
            name
            description
            hostAddress
            cloudEnabled
            cloudRESTEnabled
        }
    }
`;

export const DELETE_TERMINAL = gql`
    mutation deleteTerminal($id: String, $shopId: String) {
        deleteTerminal(id: $id, shopId: $shopId)
    }
`;

export const UPDATE_TERMINAL = gql`
    mutation updateTerminal($terminal: TerminalInput) {
        updateTerminal(terminal: $terminal) {
            id
            terminalId
            shopId
            name
            description
        }
    }
`;

export const ADD_TERMINAL_TO_BAXI = gql`
    mutation addTerminalToBaxiAgent($terminal: TerminalInput) {
        addTerminalToBaxiAgent(terminal: $terminal) {
            operationSuccess
            errorText
        }
    }
`;
