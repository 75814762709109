import gql from "graphql-tag";

import { SERVICE_DISRUPTION_ATTRIBUTES } from "./../fragments/sharedFragments";

export const UPSERT_SERVICE_DISRUPTIONS = gql`
    mutation upsertServiceDisruptions($serviceDisruptions: [ServiceDisruptionInput]) {
        upsertServiceDisruptions(serviceDisruptions: $serviceDisruptions) {
            ... on ServiceDisruption {
                ...serviceDisruptionResults
            }
        }
    }
    ${SERVICE_DISRUPTION_ATTRIBUTES}
`;