import gql from "graphql-tag";

export const UPSERT_COMBO_DISCOUNT = gql`
    mutation upsertComboDiscount($comboDiscount: ComboDiscountInput) {
        upsertComboDiscount(comboDiscount: $comboDiscount) {
            id
            companyId
            name
            description
            shopIds
            startDate
            endDate
            staticPrice
            type
            canCombine
            comboCategories {
                limit
                maxPerPurchase
                menuProductIds
            }
            disabled
        }
    }
`;

export const DELETE_COMBO_DISCOUNT = gql`
    mutation deleteComboDiscount($companyId: String!, $comboDiscountId: String!) {
        deleteComboDiscount(companyId: $companyId, comboDiscountId: $comboDiscountId)
    }
`;