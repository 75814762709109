import gql from "graphql-tag";

export const ADD_CHANGELOG = gql`
    mutation addChangelog($qoplaChangelog: QoplaChangelogInput) {
        addChangelog(qoplaChangelog: $qoplaChangelog) {
            id
            version
            createdAt
            message
        }
    }
`;

export const UPDATE_CHANGELOG = gql`
    mutation updateChangelog($qoplaChangelog: QoplaChangelogInput) {
        updateChangelog(qoplaChangelog: $qoplaChangelog) {
            id
            version
            createdAt
            message
        }
    }
`;

export const DELETE_CHANGELOG = gql`
    mutation deleteChangelog($changelogId: String) {
        deleteChangelog(changelogId: $changelogId)
    }
`;
